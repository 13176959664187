import React from 'react'
import { motiefKleur } from '../../helper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ResultatenTableHeaderSimple from '../resultaten/tableheader-simple'

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed'
  },  
  row: {
    backgroundColor: props => props.grey ? '#eee' : theme.palette.primary.superlight,
    '& td':{
      padding: '4px 16px'
    },
    '& td:nth-child(1)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      width: 57,
      left: 0,
      padding:13
    },
    '& td:nth-child(2)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      // whiteSpace: props => props.smallScreen ? 'normal' : 'inherit',
      width: 140,
      left: 57
    },
    '& td:nth-child(3)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      fontWeight: 'bold',
      width: 90,
      left: 197
    },
    '& td:nth-child(4)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      color: 'rgb(52,196,165)',
      fontWeight: 'bold',
      width: 90,
      left: 287
    },
    '& td:nth-child(n+5)': {
      fontWeight: 500,
      paddingLeft: 3,
      paddingRight: 3,
      wordBreak: 'break-word',
      width: 82
    }    
  },
  sticky: {
    position: 'sticky'
  },

}))

const DeelEigenWagenTable = ({ labels, language, selectedMotiefs, shareOwnCar, resultOwnerCar, priceView, totalKm }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({smallScreen, nbrCols: selectedMotiefs.length + 4})

  const saveRatio = (resultOwnerCar.jaarPrijsMotiefs - shareOwnCar.prijs) / resultOwnerCar.jaarPrijsMotiefs

  return (
      <Table className={classes.table} >
        <ResultatenTableHeaderSimple
          labels={labels}
          language={language}
          resultOwnerCar={resultOwnerCar}
          selectedMotiefs={selectedMotiefs}
        />
        <TableBody>
            <TableRow className={classes.row}>
              <TableCell key='a' className={classes.sticky} />
              <TableCell key='b' className={classes.sticky}>
                <span>{labels.titel_gedeeldeeigenwagen[language]}</span>
              </TableCell>
              <TableCell key='c' className={smallScreen ? '' : ' ' + classes.sticky} align="center">
                  <span>{
                    priceView === 'y'
                      ? `${Math.round(resultOwnerCar.jaarPrijsMotiefs - shareOwnCar.prijs).toLocaleString('nl-BE')} €`
                      : `${((resultOwnerCar.jaarPrijsMotiefs - shareOwnCar.prijs)/totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                    }
                  </span>
              </TableCell>
              <TableCell key='d' className={smallScreen ? '' : ' ' + classes.sticky} align="center">
                <span>{
                    priceView === 'y'
                      ? `+${Math.round(shareOwnCar.prijs).toLocaleString('nl-BE')} €`
                      : `+${(shareOwnCar.prijs/totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                  }
                </span>
              </TableCell>
              {selectedMotiefs.map((sm, idx) =>
                <TableCell key={idx} align="center" style={{ color: motiefKleur(sm.soort, theme) }}>
                  {
                    priceView === 'y'
                      ? `${Math.round(resultOwnerCar.motiefs[idx].jaarPrijs * saveRatio).toLocaleString('nl-BE')} €`
                      : `${((resultOwnerCar.motiefs[idx].jaarPrijs * saveRatio)/totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                  }
                </TableCell>
              )}
            </TableRow>
        </TableBody>
      </Table>
  )
}

export default DeelEigenWagenTable
