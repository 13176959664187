import React, { useEffect } from 'react'
import { motiefKleur } from '../../helper'
import Draggable from 'react-draggable'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  modaltitle: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    '& div:nth-child(1)': {
      fontSize: 14,
      width: 150,
      padding: 6
    }
  },
  wrapper: {
    display: 'block'
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    alignSelf: 'flex-start',
    width: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  labels: {
    marginRight: 20,
    '&>div:nth-child(1)': {
      height: 57
    },
    '&>div:nth-child(2)': {
      height: 45
    },
    '&>div:nth-child(3)': {
      height: 28
    }
  },
  motief: {
    padding: 10
  },
  motiefresult: {
    padding: 10,
    textAlign: 'right'
  },
  bottomline: {
    paddingTop: 20,
    height: 60,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  result: {
    fontWeight: 'bold',
    // paddingRight: 30,
    fontSize: 18
  },
  calcButton: {
    color: '#fff'
  },
  topCar: {
    borderTop: '1px solid #ccc'
  },
  modalButtonContainer: {
    '&>button.MuiButton-containedPrimary': {
      marginLeft: 10,
      color: '#fff'
    }
  }
}))

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const CombinedResultModal = ({ open, onClickClose, onHandleSaveCombi, language, labelsModule, selectedMotiefs, abos, ownerCars, combi, onHandleChangeAbo }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { uuid, selectedAbos, selectedOwnerCars, total, results } = combi

  const handleChangeAbo = (idx) => (event) => {
    if (typeof(event.target.value) === 'string' && event.target.value.startsWith('c')) {
      onHandleChangeAbo(idx, Number(event.target.value.substr(1)), false)
    } else {
      onHandleChangeAbo(idx, event.target.value, true)
    }
  }

  const onClickSave = () => {
    onHandleSaveCombi()
    onClickClose()
  }
  
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    (!open || combi == null) ? null :
     (
       
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="lg" onClose={onClickClose} open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disablePortal
      disableBackdropClick
    >
      <DialogTitle className={classes.modaltitle} id="draggable-dialog-title">
        {uuid == null ? labelsModule.titel_popup_combi[language] : labelsModule.knop_popup_aanpassen[language]}
      </DialogTitle>
       
      <DialogContent>
    
      <div className={classes.wrapper}>
        <div className={classes.container}>
        {/* <ReactMarkdown children={labelsModule.intro[language]} /> */}

        {selectedMotiefs.map((sm, idx) =>
          <div key={`motief-${idx}`} style={{marginRight: -1, marginBottom: -1}}>
            <div className={classes.motief} style={{ color: motiefKleur(sm.soort, theme), fontWeight: 'bold' }}>{sm.naam}</div>
            <div>
              <FormControl className={classes.formControl} key={idx}>
                {/* <InputLabel id="demo-simple-select-label">Abonnement</InputLabel> */}
                <Select
                  style={{ color: motiefKleur(sm.soort, theme) }}
                  variant='outlined'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAbos[idx] != null ? selectedAbos[idx].id : selectedOwnerCars[idx] != null ? ("c" + selectedOwnerCars[idx].id) : ''}
                  onChange={handleChangeAbo(idx)}
                >
                  {
                    abos[idx].map((abo) =>
                      <MenuItem value={abo.id} key={abo.id}>{abo.naam}</MenuItem>
                    )
                  }
                  {
                    ownerCars.map((soc,idx) =>
                      <MenuItem className={idx === 0 ? classes.topCar : ''} value={"c" + soc.id} key={"c" + soc.id}>{labelsModule.label_eigen_wagen[language]}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classes.motiefresult} style={{ color: motiefKleur(sm.soort, theme), fontWeight: 'bold' }}>{results[idx] == null ? `-` : `${Math.round(results[idx]).toLocaleString('nl-BE')} €`}</div>
          </div>
        )}
      </div>
        <div className={classes.bottomline}>
          <span className={classes.result}>              
            {
              total == null ? (<span>{`${labelsModule.label_totaal[language]}: 0 €`}</span>) : (<span>{`${labelsModule.label_totaal[language]}: ${Math.round(total).toLocaleString('nl-BE')} €`}</span>)
            }
          </span> 
        </div>
      </div>
{/*
      <div className={classes.title}>
        <Button size="large"  color="secondary" onClick={handleDelete} startIcon={<DeleteIcon />} >{``}</Button>
      </div>
    */}
    

      </DialogContent>
        
        
      <DialogActions className={classes.modalButtonContainer}>
        <Button disableElevation={true} size="medium" variant="outlined" color="primary" onClick={onClickClose}>{labelsModule.knop_popup_annuleren[language]}</Button>
        <Button disableElevation={true} size="medium" variant="contained" color="primary" onClick={onClickSave}>{uuid == null ? labelsModule.knop_popup_toevoegen[language] : labelsModule.knop_popup_aanpassen[language]}</Button>
      </DialogActions>
    </Dialog>
  )


  )
}

export default CombinedResultModal
