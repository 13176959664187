import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { getCarIcon } from '../../caricons/index'
import { motiefKleur } from '../../helper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '6px 0',
    '& svg': {
      marginRight: 12,
      fill: props => motiefKleur(props.soort, theme)
    },
    '& >:nth-child(2)': {
      marginRight: 10,
    },
    '& >:nth-child(3)': {
      fontWeight: 'bold'
    }
  },
  carIcon: {
    stroke: props => motiefKleur(props.soort, theme)
  },
  modalContent: {
    // padding: 20,
    // minWidth: 350,
    // minHeight: 500
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    '&:nth-child(-n+2)': {
      marginBottom: 40
    }
  },
  slider: {
    // minWidth: 200,
    marginLeft: 30,
    marginRight: 30,
    // marginTop: 32
  },
  number: {
    width: 140
  },
  placeholder: {
    height: 38
  },
  modaltitle: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
  },
  modalButtonContainer: {
    '&>button.MuiButton-containedPrimary': {
      marginLeft: 10,
      color: '#fff'
    }
  }
}))

const NON_DIGIT = '/[^d]/g'
const makeItInt = (value) => {
  const parsedValue = parseInt(value.replace(NON_DIGIT, ''))
  if (parsedValue >= 0) {
    return parsedValue
  } else {
    return 0
  }
}

const MotiefEdit = ({ language, labelsModule, open, handleClose, handleUpdateMotief, handleSelectMotief, idx, motief, autos, autoEditable }) => {
  const classes = useStyles({ soort: motief.soort })
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { id, labels, soort, naam, frequentie, frequentietype, afstand, duur, duureenheid, moment, auto, weekend } = motief

  const [thisAuto, setThisAuto] = useState(null)
  const [thisNaam, setThisNaam] = useState(null)  
  const [thisFrequentie, setThisFrequentie] = useState(null)
  const [thisFrequentieType, setThisFrequentieType] = useState(null)
  const [thisAfstand, setThisAfstand] = useState(null)
  const [thisDuur, setThisDuur] = useState(null)
  const [thisDuureenheid, setThisDuurEenheid] = useState(null)
  const [thisMoment, setThisMoment] = useState(null)
  const [thisWeekend, setThisWeekend] = useState(null)

  const onChangeAuto = (e) => { setThisAuto(autos.find((a) => a.id === e.target.value)) }
  const onChangeNaam = (e,v) => { setThisNaam(e.target.value) }
  const onChangeFrequentie = (e,v) => { setThisFrequentie(v) }
  const onChangeFrequentietype = (e) => {
    setThisFrequentieType(e.target.value)
    if (thisDuureenheid === 'dag') {
      setThisFrequentie(1)
      setThisDuur(1)  
    } else {
      setThisFrequentie(1)
    }
  }
  const onChangeAfstand = (e,v) => { setThisAfstand(makeItInt(e.target.value)) }
  const onChangeDuur = (e,v) => {
    setThisDuur(v)
    if (thisDuureenheid === 'dag') {
      if (v > 1) {
        if (v === 2) {
          setThisMoment('dag')
        } else {
          setThisMoment('dag')
          setThisWeekend(false)
        }
      }      
    }
  }
  const onChangeDuureenheid = (e) => {
    setThisDuurEenheid(e.target.value)
    setThisDuur(1)
  }
  const onChangeMoment = (e) => {
    setThisMoment(thisMoment==='nacht'?'dag':'nacht')
    setThisWeekend(false)
  }
  const onChangeWeekend = (e) => {
    setThisWeekend(!thisWeekend)
    if (thisWeekend) {
      setThisMoment('dag')
    }
  }

  const mapMotief = () => ({ 
      id,
      soort,
      auto: thisAuto,
      naam: thisNaam,
      frequentie: thisFrequentie,
      frequentietype: thisFrequentieType,
      afstand: thisAfstand,
      duur: thisDuur,
      duureenheid: thisDuureenheid,
      moment: thisMoment,
      weekend: thisWeekend
  })

  const onClickClose = () => {
    handleClose()
  }
 
  const onClickSave = () => {
    if (idx === null) {
      handleClose()
      handleSelectMotief(mapMotief())
    } else {
      handleClose()
      handleUpdateMotief(idx, mapMotief())
    }
  }

  useEffect(() => {
    // console.log('useEffect', auto, naam, frequentie, frequentietype, afstand, duur, duureenheid, moment, weekend);
    setThisAuto(auto)
    setThisNaam(naam)
    setThisFrequentie(frequentie)
    setThisFrequentieType(frequentietype)
    setThisAfstand(afstand)
    setThisDuur(duur)
    setThisDuurEenheid(duureenheid)
    setThisMoment(moment)
    setThisWeekend(weekend)
  // }, [auto, naam, frequentie, frequentietype, afstand, duur, duureenheid, moment, weekend])
  }, [labels, language, auto, naam, frequentie, frequentietype, afstand, duur, duureenheid, moment, weekend])

  return (
    (!open || thisAuto == null) ? null :
     (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="sm" onClose={onClickClose} open={open}>
      <DialogTitle className={classes.modaltitle}>
        {thisNaam}
        <Typography variant="body2"><ReactMarkdown children={labelsModule.info_popup[language]} /></Typography>
      </DialogTitle>
       
      <DialogContent className={classes.modalContent}>
        <div className={classes.element}>
          <Typography gutterBottom className={classes.item}><SyncAltIcon />{labelsModule.label_naam[language]}:</Typography>
          <TextField
            className={classes.number}
            onChange={onChangeNaam}
            value={thisNaam}
          />
        </div>
          
        <div className={classes.element}>
          <Typography gutterBottom className={classes.item}><EventNoteIcon />{labelsModule.label_frequentie[language]}:</Typography>
          <Slider
            className={classes.slider}
            value={thisFrequentie}
            defaultValue={0}
            valueLabelDisplay="on"
            step={1}
            min={1}
            max={thisFrequentieType==='week' ? 7 : thisFrequentieType==='maand' ? 4 : 12}
            onChange={onChangeFrequentie}
            // onChangeCommitted={onChangeFrequentieCommit}
          />
          <Select
            value={thisFrequentieType}
            onChange={onChangeFrequentietype}
          >
            <MenuItem value={'week'} key={'week'}>{`${labelsModule.label_per[language]} ${labelsModule.label_week[language]}`}</MenuItem>
            <MenuItem value={'maand'} key={'maand'}>{`${labelsModule.label_per[language]} ${labelsModule.label_maand[language]}`}</MenuItem>
            <MenuItem value={'jaar'} key={'jaar'}>{`${labelsModule.label_per[language]} ${labelsModule.label_jaar[language]}`}</MenuItem>
          </Select>
        </div>

        <div className={classes.element}>
          <Typography gutterBottom className={classes.item}><ScheduleIcon />{labelsModule.label_duur[language]}:</Typography>
          <Slider
            className={classes.slider}
            value={thisDuur}
            defaultValue={0}
            valueLabelDisplay="on"
            step={1}
            min={1}
            max={thisDuureenheid==='minuten' ? 60 : (thisDuureenheid==='uur' ? 24 : (thisFrequentieType === 'week' ? 7 : (thisFrequentieType === 'maand' ? 31 : 31)))}
            onChange={onChangeDuur}
            // onChangeCommitted={onChangeDuurCommit}
          />
          <Select
            value={thisDuureenheid}
            onChange={onChangeDuureenheid}
          >
            <MenuItem value={'minuten'} key={'minuten'}>{labelsModule.label_minuut[language]}</MenuItem>
            <MenuItem value={'uur'} key={'uur'}>{labelsModule.label_uur[language]}</MenuItem>
            <MenuItem value={'dag'} key={'dag'}>{labelsModule.label_dag[language]}</MenuItem>
          </Select>
        </div>

        {
          ((thisDuureenheid === 'uur' && thisDuur <= 12) || thisDuureenheid==='minuten') ? (
            <div className={classes.element}>
              <Typography gutterBottom className={classes.item}>
                {thisMoment==='nacht' ? <Brightness2OutlinedIcon /> : <WbSunnyOutlinedIcon />}
                {labelsModule.label_moment[language]}
              </Typography>
              <Select
                value={thisMoment}
                onChange={onChangeMoment}
              >
                <MenuItem value={'dag'} key={'dag'}>{labelsModule.label_overdag[language]}</MenuItem>
                <MenuItem value={'nacht'} key={'nacht'}>{labelsModule.label_snachts[language]}</MenuItem>
              </Select>
            </div>
          ) : ((thisDuureenheid === 'dag' && thisDuur === 2) ? (
            <div className={classes.element}>
              <Typography gutterBottom className={classes.item}><BeachAccessOutlinedIcon />{labelsModule.label_weekend[language]}:</Typography>
              <Switch
                color="primary"
                checked={thisWeekend}
                onChange={onChangeWeekend}
                name="weekend"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          ) : (
            <div className={classes.placeholder} />
          ))
        }
        
        <div className={classes.element}>
          <Typography gutterBottom className={classes.item}><SyncAltIcon />{labelsModule.label_afstand[language]}:</Typography>
          <TextField
            className={classes.number}
            type="number" onChange={onChangeAfstand}
            value={thisAfstand.toString()}
            inputProps={{ min: 0 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{labelsModule.label_km_ht[language]}</InputAdornment>
            }}
          />
        </div>

        <div className={classes.element}>
          <Typography gutterBottom className={classes.item + ' ' + classes.carIcon}>{getCarIcon(thisAuto.volgnr, 'sm')}{labelsModule.label_wagen[language]}:</Typography>
          <Select
            value={thisAuto.id}
            onChange={onChangeAuto}
            disabled={!autoEditable}
          >
            {
              autos.map((auto) =>
                <MenuItem value={auto.id} key={auto.id}>{auto.labels[0][language]}</MenuItem>
              )
            }
          </Select>
        </div>
      </DialogContent>
        
        
      <DialogActions className={classes.modalButtonContainer}>
        <Button disableElevation={true} size="medium" variant="outlined" color="primary" onClick={onClickClose}>{labelsModule.knop_popup_annuleren[language]}</Button>
        <Button disableElevation={true} size="medium" variant="contained" color="primary" onClick={onClickSave}>
          {idx === null ? labelsModule.knop_popup_toevoegen[language] : labelsModule.knop_popup_ok[language]}
        </Button>
      </DialogActions>
    </Dialog>
  )
  )
}

export default MotiefEdit
