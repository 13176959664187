import React from 'react'

const Speed = ({ size }) => (
  <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 512 512">
 <g>
	<g>
		<path d="M256,0C114.841,0,0,114.393,0,255c0,140.905,114.956,257,256,257c140.969,0,256-115.049,256-256
			C512,115.03,396.951,0,256,0z M256,482C131.383,482,30,380.168,30,255C30,130.935,131.383,30,256,30s226,101.383,226,226
			S380.617,482,256,482z"/>
	</g>
</g>
<g>
	<g>
		<path d="M256,61C147.898,61,61,148.997,61,256c0,51.478,19.892,99.943,56.031,136.674c5.65,7.006,16.022,7.5,22.354,1.168
			c0.049-0.049,0.09-0.103,0.138-0.152l42.231-42.231c5.858-5.857,5.858-15.355,0-21.213c-5.856-5.857-15.354-5.858-21.213,0
			l-31.291,31.29c-21.56-25.787-34.565-57.13-37.568-90.536H136c8.284,0,15-6.716,15-15s-6.716-15-15-15H91.689
			c3.102-34.25,16.708-65.512,37.571-90.527l31.281,31.281c5.857,5.858,15.356,5.858,21.213,0c5.858-5.858,5.858-15.355,0-21.213
			l-31.281-31.281C175.488,108.396,206.75,94.791,241,91.689V136c0,8.284,6.716,15,15,15s15-6.716,15-15V91.689
			c34.25,3.102,65.512,16.708,90.527,37.571l-31.281,31.281c-5.858,5.857-5.858,15.355,0,21.213c5.857,5.858,15.355,5.858,21.213,0
			l31.281-31.281c20.864,25.015,34.469,56.277,37.571,90.527H376c-8.284,0-15,6.716-15,15s6.716,15,15,15h44.318
			c-3.002,33.406-16.008,64.749-37.568,90.537l-31.291-31.29c-5.857-5.858-15.355-5.858-21.213,0
			c-5.858,5.858-5.858,15.355,0,21.213l42.231,42.231c0.048,0.049,0.089,0.104,0.138,0.152c6.332,6.332,16.704,5.837,22.354-1.168
			C431.108,355.943,451,307.478,451,256C451,148.592,363.603,61,256,61z"/>
	</g>
</g>
<g>
	<g>
		<path d="M312.621,199.379c-5.857-5.858-15.355-5.857-21.213-0.001l-16.019,16.019C269.514,212.581,262.938,211,256,211
			c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45c0-6.938-1.581-13.514-4.398-19.389l16.019-16.019
			C318.479,214.734,318.479,205.237,312.621,199.379z M256,271c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15
			S264.271,271,256,271z"/>
	</g>
</g>
<g>
	<g>
		<path d="M301,391h-90c-8.284,0-15,6.716-15,15s6.716,15,15,15h90c8.284,0,15-6.716,15-15S309.284,391,301,391z"/>
	</g>
</g>

  </svg>
)

export default Speed
