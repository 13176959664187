import React, { useState, useEffect } from 'react'
import { getCarIcon } from '../../caricons/index'
// import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
// import DeleteIcon from '@material-ui/icons/HighlightOff'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined'
// import EvStationOutlinedIcon from '@material-ui/icons/EvStationOutlined'
import PlugIcon from '../../icons/plug'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined'
// import LooksOneOutlinedIcon from '@material-ui/icons/LooksOneOutlined'
import RvHookupOutlinedIcon from '@material-ui/icons/RvHookupOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined'
import SpeedIcon from '../../icons/speed'
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  car: {
    padding: 20,
    paddingTop: 10,
    height: 315
  },
  editButton: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      color: '#fff',
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10
  },
  title: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,

    display: 'flex',
    justifyContent: 'space-between',
    '& h6': {
      fontWeight: 'bold'
    },
    '& svg:nth-child(1)': {
      fill: theme.palette.text.primary
    },
    '& svg:nth-child(2)': {
      fill: theme.palette.primary.main
    },
    '& svg:nth-child(2):hover': {
      cursor: 'pointer'
    }
  },
  subtitle: {},
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    '& svg': {
      marginRight: 10,
      fill: theme.palette.secondary.main
    },
    '& >:nth-child(2)': {
      marginRight: 10
    },
    '& >:nth-child(3)': {
      fontWeight: 'bold'
    }
  },
  modaltitle: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
  },
  submodaltitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      stroke: theme.palette.text.primary,
      marginRight: 25,
    }
  },
  modalContent: {
    // padding: 20,
    // minWidth: 250
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 10
  },
  number: {
    width: 100
  },
  modalButtonContainer: {
    marginTop: 30,
    // padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '&>button.MuiButton-containedPrimary': {
      marginLeft: 10,
      color: '#fff'
    }
  }
}))

const NON_DIGIT = '/[^d]/g'
const makeItInt = (value) => {
  const parsedValue = parseInt(value.replace(NON_DIGIT, ''))
  if (parsedValue >= 0) {
    return parsedValue
  } else {
    return 0
  }
}

const OwnerCarEdit = ({language, labelsModule, open, adding, ownerCar, handleUpdateOwnerCar, handleClose}) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { id, auto, nieuwprijs, nieuwprijselektrisch, leeftijd, kmstand, verzekering, verzekeringelektrisch, pechhulp, garage, onderhoud, onderhoudelektrisch, herstellingen, herstellingenelektrisch, belasting, belastingelektrisch, keuring, carwash, electric } = ownerCar

  const [thisElectric, setThisElectric] = useState(null)
  const [thisNieuwprijs, setThisNieuwprijs] = useState(null)
  const [thisNieuwprijselektrisch, setThisNieuwprijselektrisch] = useState(null)
  const [thisLeeftijd, setThisLeeftijd] = useState(null)
  const [thisKmstand, setThisKmstand] = useState(null)
  const [thisVerzekering, setThisVerzekering] = useState(null)
  const [thisVerzekeringelektrisch, setThisVerzekeringelektrisch] = useState(null)
  const [thisPechhulp, setThisPechhulp] = useState(null)
  const [thisGarage, setThisGarage] = useState(null)

  const [thisOnderhoud, setThisOnderhoud] = useState(null)
  const [thisOnderhoudelektrisch, setThisOnderhoudelektrisch] = useState(null)
  const [thisHerstellingen, setThisHerstellingen] = useState(null)
  const [thisHerstellingenelektrisch, setThisHerstellingenelektrisch] = useState(null)
  const [thisBelasting, setThisBelasting] = useState(null)
  const [thisBelastingelektrisch, setThisBelastingelektrisch] = useState(null)
  const [thisKeuring, setThisKeuring] = useState(null)
  const [thisCarwash, setThisCarwash] = useState(null)
  // const [this, setThis] = useState(null)

  const onChangeElectric = (e) => { setThisElectric(!thisElectric) }
  const onChangeNieuwprijs = (e,v) => { setThisNieuwprijs(makeItInt(e.target.value)) }
  const onChangeNieuwprijselektrisch = (e,v) => { setThisNieuwprijselektrisch(makeItInt(e.target.value)) }
  const onChangeLeeftijd = (e,v) => { setThisLeeftijd(makeItInt(e.target.value)) }
  const onChangeKmstand = (e,v) => { setThisKmstand(makeItInt(e.target.value)) }
  const onChangeVerzekering = (e,v) => { setThisVerzekering(makeItInt(e.target.value)) }
  const onChangeVerzekeringelektrisch = (e,v) => { setThisVerzekeringelektrisch(makeItInt(e.target.value)) }
  const onChangePechhulp = (e,v) => { setThisPechhulp(makeItInt(e.target.value)) }
  const onChangeGarage = (e,v) => { setThisGarage(makeItInt(e.target.value)) }

  const onChangeOnderhoud = (e,v) => { setThisOnderhoud(makeItInt(e.target.value)) }
  const onChangeOnderhoudelektrisch = (e,v) => { setThisOnderhoudelektrisch(makeItInt(e.target.value)) }
  const onChangeHerstellingen = (e,v) => { setThisHerstellingen(makeItInt(e.target.value)) }
  const onChangeHerstellingenelektrisch = (e,v) => { setThisHerstellingenelektrisch(makeItInt(e.target.value)) }
  const onChangeBelasting = (e,v) => { setThisBelasting(makeItInt(e.target.value)) }
  const onChangeBelastingelektrisch = (e,v) => { setThisBelastingelektrisch(makeItInt(e.target.value)) }
  const onChangeKeuring = (e,v) => { setThisKeuring(makeItInt(e.target.value)) }
  const onChangeCarwash = (e,v) => { setThisCarwash(makeItInt(e.target.value)) }

  const mapOwnerCar = () => ({
    id,
    auto,
    nieuwprijs: thisNieuwprijs,
    nieuwprijselektrisch: thisNieuwprijselektrisch,
    leeftijd: thisLeeftijd,
    kmstand: thisKmstand,
    verzekering: thisVerzekering,
    verzekeringelektrisch: thisVerzekeringelektrisch,
    pechhulp: thisPechhulp,
    garage: thisGarage,
    onderhoud: thisOnderhoud,
    onderhoudelektrisch: thisOnderhoudelektrisch,
    herstellingen: thisHerstellingen,
    herstellingenelektrisch: thisHerstellingenelektrisch,
    belasting: thisBelasting,
    belastingelektrisch: thisBelastingelektrisch,
    keuring: thisKeuring,
    carwash: thisCarwash,
    electric: thisElectric
  })

  const onClickClose = () => {
    handleClose()
  }
 
  const onClickSave = () => {
    handleClose()
    handleUpdateOwnerCar(mapOwnerCar())
  }

  useEffect(() => {
    setThisElectric(electric)
    setThisNieuwprijs(nieuwprijs)
    setThisNieuwprijselektrisch(nieuwprijselektrisch)
    setThisLeeftijd(leeftijd)
    setThisKmstand(kmstand)
    setThisVerzekering(verzekering)
    setThisVerzekeringelektrisch(verzekeringelektrisch)
    setThisPechhulp(pechhulp)
    setThisGarage(garage)
    setThisOnderhoud(onderhoud)
    setThisOnderhoudelektrisch(onderhoudelektrisch)
    setThisHerstellingen(herstellingen)
    setThisHerstellingenelektrisch(herstellingenelektrisch)
    setThisBelasting(belasting)
    setThisBelastingelektrisch(belastingelektrisch)
    setThisKeuring(keuring)
    setThisCarwash(carwash)
  }, [nieuwprijs, nieuwprijselektrisch, leeftijd, kmstand, verzekering, verzekeringelektrisch, pechhulp, garage, onderhoud, onderhoudelektrisch, herstellingen, herstellingenelektrisch, belasting, belastingelektrisch, keuring, carwash, electric])

  return (
    (!open || thisNieuwprijs == null) ? null : (
      <Dialog fullScreen={fullScreen} fullWidth maxWidth="sm" onClose={handleClose} open={open}>
        <DialogTitle className={classes.modaltitle}>
          <span className={classes.submodaltitle}>
            {getCarIcon(auto.volgnr, 'md')}</span><span>{`${labelsModule.label_popup_title[language]}: ${auto.labels[0][language]}`}
          </span>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}>{electric? <PlugIcon /> : <LocalGasStationOutlinedIcon />}{labelsModule.label_elektrisch[language]}:</Typography>
            <Switch
              color="secondary"
              checked={thisElectric}
              onChange={onChangeElectric}
              name="electric"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><LocalOfferOutlinedIcon />{labelsModule.label_nieuwprijs[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number"
              onChange={thisElectric ? onChangeNieuwprijselektrisch : onChangeNieuwprijs}
              value={thisElectric ? thisNieuwprijselektrisch.toString() : thisNieuwprijs.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><TimelapseOutlinedIcon />{labelsModule.label_leeftijd[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangeLeeftijd}
              value={thisLeeftijd.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{labelsModule.label_jaar[language]}</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><SpeedIcon size="24" />{labelsModule.label_kilometerstand[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangeKmstand}
              value={thisKmstand.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{labelsModule.label_km[language]}</InputAdornment>
              }}
            />
          </div>

          <br />
          <Typography variant="h6">{labelsModule.titel_vaste_kosten[language]}:</Typography>
          <br />
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><SecurityOutlinedIcon />{labelsModule.label_verzekering[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={thisElectric ? onChangeVerzekeringelektrisch : onChangeVerzekering}
              value={thisElectric ? thisVerzekeringelektrisch.toString() : thisVerzekering.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><RvHookupOutlinedIcon />{labelsModule.label_pechhulp[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangePechhulp}
              value={thisPechhulp.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><HomeOutlinedIcon />{labelsModule.label_parkeerkosten[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangeGarage}
              value={thisGarage.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>

          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><SettingsOutlinedIcon />{labelsModule.label_onderhoud[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={thisElectric ? onChangeOnderhoudelektrisch : onChangeOnderhoud}
              value={thisElectric ? thisOnderhoudelektrisch.toString() : thisOnderhoud.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><BuildOutlinedIcon />{labelsModule.label_herstellingen[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={thisElectric ? onChangeHerstellingenelektrisch : onChangeHerstellingen}
              value={thisElectric ? thisHerstellingenelektrisch.toString() : thisHerstellingen.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><ScatterPlotOutlinedIcon />{labelsModule.label_belasting[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={thisElectric ? onChangeBelastingelektrisch : onChangeBelasting}
              value={thisElectric ? thisBelastingelektrisch.toString() : thisBelasting.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><SearchOutlinedIcon />{labelsModule.label_keuring[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangeKeuring}
              value={thisKeuring.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
          <div className={classes.element}>
            <Typography gutterBottom className={classes.item}><LocalCarWashOutlinedIcon />{labelsModule.label_carwash[language]}:</Typography>
            <TextField
              className={classes.number}
              type="number" onChange={onChangeCarwash}
              value={thisCarwash.toString()}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </div>
{/*}          <div className={classes.element}>
            <Typography gutterBottom>{`Andere vaste kosten: onderhoud (${onderhoud}€), herstellingen (${herstellingen}€), belasting (${belasting}€), keuring (${keuring}€)`}</Typography>
          </div>
*/}
        </DialogContent>


        <DialogActions className={classes.modalButtonContainer}>
          <Button disableElevation={true} size="medium" variant="outlined" color="primary" onClick={onClickClose}>{labelsModule.knop_popup_annuleren[language]}</Button>
          <Button disableElevation={true} size="medium" variant="contained" color="primary" onClick={onClickSave}>
            {adding ? labelsModule.knop_popup_toevoegen[language] : labelsModule.knop_popup_ok[language]}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default OwnerCarEdit
