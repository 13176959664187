import React from 'react'
import Draggable from 'react-draggable'
import ReactMarkdown from 'react-markdown'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Height } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: 70,
    marginLeft: 10,
    width: '95%',
  },
  valueLabel: {
    transform: 'scale(1) translateY(-36px) !important',
    left: 'calc(-50% - 18px)',
    '& > span': {
      width: 60,
      height: 60,
    },
      '& div': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 6,
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 14,
        '& span:nth-child(2)': {
          fontWeight: 'normal',
          fontSize: 12,
        }
      }
  },
  modalButtonContainer: {
    '&>button.MuiButton-containedPrimary': {
      marginLeft: 10,
      color: '#fff'
    }
  }  

}))

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const DeelEigenWagenModal = ({ open, labelsModule, language, shareOwnCar, totalKm, totalTime, resultOwnerCar, onClickClose, onHandleSave, onChangeSharedKms  }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const onChangeKms = (e,v) => {
    onChangeSharedKms(v)
  }

  const onClickSave = () => {
    onHandleSave()
  }

  return (
    (!open) ? null :
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="sm" onClose={onClickClose} open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disablePortal
      disableBackdropClick
    >
      <DialogTitle className={classes.modaltitle} id="draggable-dialog-title">
        {labelsModule.titel_popup[language]}
      </DialogTitle>
      <DialogContent>
        <div className={classes.totalTime}>
           <ReactMarkdown
            children={labelsModule.intro_popup[language].replace('$bezetting', Math.round(100 - (totalTime/ 525600)*100)).replace('$kms', totalKm.toLocaleString('nl-BE'))} 
          />
        </div>

        {(totalTime / 525600 < 0.7) &&
          <div>
            <ReactMarkdown
              components={{
                a: (props) => { return <a href={props.href} target='_blank'>{props.children[0]}</a> }
              }}
            >{labelsModule.slider_label[language]}</ReactMarkdown>
            <Slider
              className={classes.slider}
              classes={{
                  valueLabel: classes.valueLabel,
              }}
              color='primary'
              value={shareOwnCar.kms}
              defaultValue={0}
              valueLabelDisplay="on"
              valueLabelFormat={(x) => <div><span>{`${x.toLocaleString('nl-BE')}`}</span><span>km</span></div>}
              step={100}
              min={0}
              // max={Math.min(30000 - totalKm, (367920-totalTime)/12 )}  // 367920 = 70% van de tijd in minuten; delen door 12 omdat we uitgaan van 5 km/h (stilstand inbegrepen)
              max={(367920-totalTime)/ 10 }  // 367920 = 70% van de tijd in minuten; delen door 10 omdat we uitgaan van 6 km/h (stilstand inbegrepen)
              onChange={onChangeKms}
              // onChangeCommitted={onChangeFrequentieCommit}
            />
            <br />
            <br />
            <div>{labelsModule.label_popup_bedrag[language]}: <strong>{`${shareOwnCar.prijs.toLocaleString('nl-BE')} €`}</strong></div>
            <br />
            <div><ReactMarkdown children={labelsModule.label_popup_bezetting[language].replace('$bezetting', Math.round(((totalTime + shareOwnCar.kms*10)/ 525600)*100))} /></div>
            <br />
            <div>{labelsModule.label_popup_kmprijs[language]}: <strong>{`${((resultOwnerCar.jaarPrijsMotiefs-shareOwnCar.prijs)/ totalKm).toFixed(2).toLocaleString('nl-BE')} €/km`}</strong></div>
            <br />
          </div>
        }
      </DialogContent>
      <DialogActions className={classes.modalButtonContainer}>
        <Button disableElevation={true} size="medium" variant="outlined" color="primary" onClick={onClickClose}>{labelsModule.knop_popup_annuleren[language]}</Button>
        <Button disableElevation={true} size="medium" variant="contained" color="primary" onClick={onClickSave}>{labelsModule.knop_popup_bewaren[language]}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeelEigenWagenModal
