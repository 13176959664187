import React from 'react'
import { ListItem, TextField } from '@material-ui/core'

export default function TextInput({ label, name, value, onChange }) {
  return (
    <ListItem style={{ marginTop: 5 }}>
      <TextField label={label} name={name} value={value} onChange={(e) => onChange(e.target)} fullWidth />
    </ListItem>
  )
}
