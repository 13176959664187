import React from 'react'

const sizes = {
  sm: 36,
  md: 64
}

const strokeWidths = {
  sm: 24,
  md: 20
}

const GezinsWagen = ({ size }) => (
  <svg width={`${sizes[size]}px`} height={`${sizes[size]}px`} viewBox="0 0 1148.02 412.19" style={{fill:'none', strokeWidth: strokeWidths[size]}}>
    <path d="M2948.75,918c-2.22,8.89-3.71,18.06-6.87,26.6-2.86,7.72-5.69,18.19-11.76,21.48-23.86,12.92-50.75,15.06-77.61,18.23,8.51-69.42-40.17-120.16-100.73-121.26-51.95-.94-115.77,45.09-100.29,129.46l-507.69-10.23c2.55-28-2.13-53.25-17.9-75.47s-37.28-36.47-64.08-41.7c-24.69-4.82-48.39-1.09-70.26,11.67-33.81,19.72-49.83,50.43-52.33,89.32-29.32-5.47-57.12-10.57-84.87-15.93a23.46,23.46,0,0,1-8.86-4.19c-27-19.51-38.41-68.19-19.22-95.54,10-14.21,10.92-27.79,10.8-43.05-.06-7.37,0-14.73,0-22.1,0-26.81,3.69-31.41,30-35.11,41.7-5.87,82.25-15.7,121.1-32.34,90.29-38.67,183.65-64.53,282.47-68.64,80.09-3.33,158,5.24,231.91,38.32q90.62,40.55,181.26,81a68.81,68.81,0,0,0,13.57,4.27c37.74,8.07,75.95,14.39,113.16,24.4,35,9.42,69.09,22.43,103.39,34.42,11.39,4,18.2,12.73,21.55,24.72,3.74,13.36,8.81,26.36,13.3,39.51ZM2542.11,743.56c-63.58-50.16-142-61.11-223.35-68.07.57,4.61.64,7.38,1.28,10,6.66,27.5,13.51,54.95,20,82.48,1.24,5.23,2.7,8.31,9.06,8.46,58.87,1.44,117.72,3.22,176.58,4.85,1.77,0,3.55-.45,2.8-.35Zm-250.71-36.11c-1.27-8-1.9-16.15-4.15-23.85-.88-3-5.38-7-8.27-7.07-42.45-.43-84.46,3.45-125.29,15.78a24.22,24.22,0,0,0-11.72,8.62,282,282,0,0,0-18,29,18.64,18.64,0,0,0-2,12c5.77,25.4,6,25.22,32.11,26,44.85,1.38,89.69,3,134.53,4.44,3.94.13,7.89,0,13.1,0C2298.17,750,2294.78,728.74,2291.39,707.45Zm-257.7,29.88c-12.21,6-16,14.37-14.58,29.28,14.67,0,29.54.12,44.41-.15,1.79,0,4.06-1.8,5.24-3.4,14.56-19.72,29-39.56,43.39-59.37l-1.93-2.16C2084.7,713.42,2059,725,2033.7,737.33Z" transform="translate(-1808.73 -640.55)"/><path d="M2121.36,966.1c0,42.66-35.59,78.29-78.59,78.63-43.19.35-80.43-36.09-80.14-78.42.3-44.35,35.67-80,79.4-80.09C2086,886.16,2121.34,921.75,2121.36,966.1Z" transform="translate(-1808.73 -640.55)"/><path d="M2830.46,966.11c0,42.66-35.59,78.29-78.59,78.63-43.19.35-80.43-36.09-80.14-78.42.3-44.35,35.67-80,79.4-80.09C2795.09,886.17,2830.44,921.76,2830.46,966.11Z" transform="translate(-1808.73 -640.55)"/>
  </svg>
)

export default GezinsWagen
