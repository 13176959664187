import React, { Fragment, useState } from 'react'
import MotiefEdit from '../motieven/motiefEdit'
import { getCarIcon } from '../../caricons/index'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import StarIcon from '@material-ui/icons/Star'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useStyles = makeStyles((theme) => ({
  tableheader: {
    padding: 6,
    background: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    verticalAlign: 'top',
    '&:nth-child(n+5)': {
      fontWeight: 'normal'
    }
  },
  motiefHeader: {
    wordBreak: 'break-word',
    '&:hover':  {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },  
  sorter: {
    '& :hover':  {
      cursor: 'pointer'
    }
  },
  headerrow: {
    '& th':{
      padding: '4px 16px'
    },
    '& th:nth-child(1)': {
      width: 57,
      left: 0,
    },
    '& th:nth-child(2)': {
      width: 140,
      left: 57,
      paddingLeft: 16,
    },
    '& th:nth-child(3)': {
      width: 90,
      left: 197,
    },
    '& th:nth-child(4)': {
      width: 90,
      left: 287,
    },
    '& th:nth-child(n+5)': {
      width: 82
    },
  },
  sticky: {
    position: 'sticky'
  },  
  star: {
    paddingLeft: 16,
    '& svg:hover': {
      cursor: 'pointer'
    }
  },
   carIcon: {
    stroke: '#fff'
  }, 
}))

const ResultatenTableHeader = ({ moduleIdx, labels, modules, language, resultOwnerCar, selectedMotiefs, autos, orderBy, order, handleUpdateMotief, handleClickSelectAll, createSortHandler }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [editMotiefIdx, setEditMotiefIdx] = useState(0)
  const [openMotief, setOpenMotief] = useState(false)

  const handleCloseMotief = () => {
    setOpenMotief(false)
  }
  const editMotief = (thisIdx) => () => {
    setEditMotiefIdx(thisIdx)
    setOpenMotief(true)
  }
  
  return (
    <Fragment>
      <MotiefEdit
        language={language}
        labelsModule={labels[modules[0]]}
        open={openMotief}
        handleClose={handleCloseMotief}
        handleUpdateMotief={handleUpdateMotief}
        idx={editMotiefIdx}
        motief={selectedMotiefs[editMotiefIdx]}
        autos={autos}
        autoEditable={true}
      />      

      <TableHead>
        <TableRow className={classes.headerrow}>
          <TableCell key='a' className={classes.tableheader + ' ' + classes.sticky} />
          <TableCell key='b' className={classes.tableheader + ' ' + classes.sticky} />
          <TableCell key='c' className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)} />
          {resultOwnerCar !=null ? (
            <TableCell key='d' className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)} />
            ) : null
          }                      
          {selectedMotiefs.map((sm, idx) =>
            <TableCell key={idx} className={classes.tableheader} align="center" sortDirection={orderBy === sm.id ? order : false}>
              <Tooltip title={sm.auto.labels[0][language]}><span className={classes.carIcon}>{getCarIcon(sm.auto.volgnr, 'sm')}</span></Tooltip>
            </TableCell>
          )}
        </TableRow>

        <TableRow className={classes.headerrow}>
          <TableCell key='a' className={classes.tableheader + ' ' + classes.sticky} />
          <TableCell key='b' className={classes.tableheader + ' ' + classes.sticky} />
          <TableCell key='c' align="center" className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)}>
            {labels[modules[moduleIdx]].label_totaal_ritten[language]}
          </TableCell>
          {resultOwnerCar !=null ? (
            <TableCell key='d' align="center" className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)}>
              {labels[modules[moduleIdx]].label_besparing[language]}
            </TableCell>
            ) : null
          }
          {selectedMotiefs.map((sm, idx) =>
            <TableCell key={idx} className={classes.tableheader} align="center" sortDirection={orderBy === sm.id ? order : false}>
              <span className={classes.motiefHeader} onClick={editMotief(idx)}>{sm.naam}</span>
            </TableCell>
          )}
        </TableRow>

        <TableRow className={classes.headerrow}>
          <TableCell key='a' className={classes.tableheader + ' ' + classes.sticky + ' ' + classes.star} >
            <Tooltip title={labels[modules[moduleIdx]].tooltip_select_all[language]}><StarIcon onClick={handleClickSelectAll} /></Tooltip>
          </TableCell>
          <TableCell key='b' className={classes.tableheader + ' ' + classes.sticky} >{labels[modules[moduleIdx]].label_aanbieder[language]}</TableCell>
          <TableCell key='c' align="center" className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)}>
            <span className={classes.sorter}>
              {orderBy === null ? 
                (order === 'asc'
                  ? <ArrowUpwardIcon onClick={createSortHandler(null)} />
                  : <ArrowDownwardIcon onClick={createSortHandler(null)} />
                ) :
                <ImportExportIcon
                  onClick={createSortHandler(null)}
                  color='disabled'
                />
              }
            </span>
          </TableCell>
          {resultOwnerCar !=null ? (
            <TableCell key='d' className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)} />
            ) : null
          }
          {selectedMotiefs.map((sm, idx) =>
            <TableCell key={idx} className={classes.tableheader} align="center" sortDirection={orderBy === sm.id ? order : false}>
              <div className ={classes.colheader}>
                <span className={classes.sorter}>
                  {orderBy === idx ? 
                    (order === 'asc'
                      ? <ArrowUpwardIcon onClick={createSortHandler(idx)} />
                      : <ArrowDownwardIcon onClick={createSortHandler(idx)} />
                    ) :
                  
                    <ImportExportIcon
                      onClick={createSortHandler(idx)}
                      color='disabled'
                    />
                  }
                </span>
              </div>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    </Fragment>
  )
}

export default ResultatenTableHeader
