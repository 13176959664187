import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    marginTop: 40,
    // padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '&>button.MuiButton-containedPrimary': {
      marginLeft: 10,
      color: '#fff'
    },
    '&>button.MuiButton-containedSecondary': {
      marginLeft: 10,
      color: '#fff'
    },
    alignItems: 'center'
  }
})

const Actions = ({ actions }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {actions}
    </div>
  )
}

export default Actions
