import React from 'react'
import OwnerCarInfo from './ownerCarInfo'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  modaltitle: {
    paddingBottom: 0,
    '& div:nth-child(1)' : {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 10,
      borderBottom: '1px dashed',
    },
    '& svg': {
      fill: theme.palette.secondary.main,
      marginTop: -6,
      marginRight: -6
    },
    '& svg:hover': {
      cursor: 'pointer'
    }
  },
  container1: {
    paddingBottom: 20
  }
}))

const OwnerCarsInfo = ({ language, labelsModule, open, ownerCars, handleClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  if (open) {
    return (
      <Dialog fullScreen={fullScreen} maxWidth="md" onClose={handleClose} open={open}>
        <DialogTitle className={classes.modaltitle}>
          <div>
            <span>{labelsModule.titel_popup_wagentypes[language]}:</span>
            <DeleteIcon onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.container1}>
            {ownerCars.map((ownerCar, idx) =>
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <OwnerCarInfo
                  language={language}
                  labelsModule={labelsModule}
                  ownerCar={ownerCar}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    )
  } else {
    return null
  }
}

export default OwnerCarsInfo
