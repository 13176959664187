import React from 'react'
import { motiefKleur } from '../../helper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  motif: {
    padding: 20,
    height: 110,
    backgroundColor: props => motiefKleur(props.soort, theme),
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    },
  },
  title: {
    // fontWeight: 'bold',
    paddingBottom: 6,
  },
  description: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      alignSelf: 'flex-end'
    }
  }
}))

const Motief = ({moduleIdx, labelsModule, modules, language, motief, handleSelect, selected}) => {
  const classes = useStyles({ soort: motief.soort })

  const { id, labels } = motief
  return (
    <Paper className={classes.motif} onClick={handleSelect(id)}>
      <Typography variant='h6' className={classes.title} >
        {labels.find(({ naam }) => naam.endsWith('titel'))[language] || ''}
        {[...Array(selected)].map((s, idx) => <CheckOutlinedIcon key={idx} /> )}
      </Typography>
      <div className={classes.description}>
        <span>{labels.find(({ naam }) => naam.endsWith('beschrijving'))[language] || ''}</span>
        <ControlPointIcon />
      </div>
    </Paper>
  )
}

export default Motief
