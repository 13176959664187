import React, { useEffect } from 'react'
import StepTitle from '../components/common/steptitle'
import Actions from '../components/common/actions'
import ReactMarkdown from 'react-markdown'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { strapiUrl } from '../service'
import { useNavigate } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import persona from '../images/persona.svg'
import { paths } from '../extras/paths'

const useStyles = makeStyles((theme) => ({
  information: {
    // '& p': {
    //   display: 'inline'
    // }
    '& a': {
      color: theme.palette.primary.main
    }
  },
  pdfButton: {
    color: '#fff',
  },  
  view: {
    margin: 'auto',
    width: '100%'
  },
  wrapper: {
    position: 'relative',
    overflow: 'auto'
  },
  table: {
    width: 'auto',
    height: '1px',  //  necessary for making the 100% child elements work properly
    minWidth: '100%'
  },
  tableheader: {
    padding: 6,
    background: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    verticalAlign: 'top'
  },
  motiefHeader: {
    '&:hover':  {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },
  total: {
    fontWeight: 'bold'
  },
  title: {
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    whiteSpace: 'nowrap',
    height: '100%',
    '&:nth-of-type(odd)': {
      backgroundColor: '#eee',
      '& td:nth-child(1)': {
        backgroundColor: '#eee',
        whiteSpace: 'normal'
      },
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
      '& td:nth-child(1)': {
        backgroundColor: '#fff',
        whiteSpace: 'normal'
      },
    },
    '& td:nth-child(1)': {
      left: 0
    },
  },
  headerrow: {
    '& th:nth-child(1)': {
      left: 0,
      paddingLeft: 16
    },
  },
  ownerCarRow: {
    '& td': {
      backgroundColor: '#ccc !important'
    }
  },
  sticky: {
    position: 'sticky'
  },
  carIcon: {
    stroke: '#fff'
  },
  aanbieder: {
    display: 'flex',
    alignItems: 'center',
    '& >:nth-child(2)': {
      marginLeft: 10
    }    
  },
  combicellFirst: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  combicell: {
    height: '100%',
    // maxWidth: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    whiteSpace: 'normal'
  },
  persona: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -40,
    '& >img': {
      width: '35%',
      minWidth: 300,
    }
  }
}))

const End = ({ moduleIdx, labels, modules, results, combis, language, selectedMotiefs }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  let navigate = useNavigate()

  const handlePrevious = () => {
    // navigate("/combineer")
    navigate(`/${paths.resultaten[language]}`)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Container maxWidth="lg">
      <StepTitle labels={labels} modules={modules} language={language} propActiveStep={moduleIdx} />
      <div className={classes.information}>
        <Typography variant="body1">
          <ReactMarkdown children={labels[modules[moduleIdx]].intro[language].split('/uploads/').join(`${strapiUrl}/uploads/` )} linkTarget='_blank' />
        </Typography>
      </div>

      <br />
      {!smallScreen &&
        <span className={classes.persona}>
          <img src={persona} />
        </span>
      }
      <Actions
        actions={[
          <Button disableElevation={true} key='prev' onClick={handlePrevious} variant="outlined" size="large" color="primary">{labels[modules[moduleIdx]].navigatie_terug[language]}</Button>,
        ]}
      />
    </Container>
  )
}

export default End
