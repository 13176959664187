import React from 'react'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 40,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -12
  },
  title2: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  regions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 16,
    '& >:nth-child(1)': {
      marginRight: 10
    },
    '& .MuiTypography-body1': {
      fontSize: '0.875rem'
    }
  },
  priceView: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 16,
    '& >:nth-child(1)': {
      marginRight: 10
    },
    '& .MuiTypography-body1': {
      fontSize: '0.875rem'
    }
  }, 
  info2: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    marginLeft: 10,
    '& svg': {
      fill: theme.palette.secondary.main
    },
    '& p': {
      marginLeft: 4,
      borderBottom: '1px dashed #ccc'
    },
    '& p:hover': {
      fill: theme.palette.primary.dark,
      cursor: 'pointer'
    }
  },
}))

const ResultatenFilters = ({ moduleIdx, labels, modules, language, showAboIncluded, showCheapest, selectedRegios, priceView, onChangeShowCheapest, onChangeShowAboIncluded, handleClickRegio, onChangePriceView, handleOpenInfo }) => {
  const classes = useStyles()

  return (
    <div className={classes.filters}>
      <div className={classes.title}>
        <div className={classes.subtitle}>
          <Switch
            color="primary"
            checked={showAboIncluded}
            onChange={onChangeShowAboIncluded}
            name="showAboIncluded"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography variant="body2">
            {labels[modules[moduleIdx]].knop_vaste_kosten[language]}
            <span className={classes.info2} onClick={handleOpenInfo(labels[modules[moduleIdx]].titel_popup_info[language], labels[modules[moduleIdx]].info_na_knop_vaste_kosten[language])}>
              <InfoIcon fontSize='small' />
              <Typography variant='body2'>{labels[modules[moduleIdx]].knop_meer_info[language]}</Typography>
            </span>
          </Typography>
        </div>
        <div className={classes.subtitle}>
          <Switch
            color="primary"
            checked={showCheapest}
            onChange={onChangeShowCheapest}
            name="showCheapest"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography variant="body2">{labels[modules[moduleIdx]].knop_goedkoopste_formule[language]}</Typography>
        </div>
      </div>
      <div className={classes.title2}>
          <div className={classes.regions}>
            <Typography variant="body2">{labels[modules[moduleIdx]].label_regio[language]}</Typography>
            <span>
              {selectedRegios.map((slr) =>
                <FormControlLabel
                  key={slr.id}
                  size="small"
                  control={<Checkbox checked={slr.selected} onClick={handleClickRegio(slr.id)} color="primary"/>}
                  label={slr[`naam_${language}`]}
                />
              )}
            </span>
          </div>
          <div className={classes.priceView}>
            <Typography variant="body2">{labels[modules[moduleIdx]].label_prijzen_per[language]}</Typography>
            <ToggleButtonGroup
              size={'small'}
              value={priceView}
              exclusive
              onChange={onChangePriceView}
            >
              <ToggleButton value='y'>{labels[modules[moduleIdx]].knop_prijzen_per_jaar[language]}</ToggleButton>
              <ToggleButton value='k'>{labels[modules[moduleIdx]].knop_prijzen_per_km[language]}</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
    </div>
  )
}

export default ResultatenFilters
