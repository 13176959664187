import React from 'react'

const sizes = {
  sm: 36,
  md: 64
}

const strokeWidths = {
  sm: 24,
  md: 20
}

const LuxeWagen = ({ size }) => (
  <svg width={`${sizes[size]}px`} height={`${sizes[size]}px`} viewBox="0 0 1149.86 351.65" style={{fill:'none', strokeWidth: strokeWidths[size]}}>
    <path d="M1814,868.48c10.52-24.56,22-48.78,31.26-73.8,6.65-17.92,17.54-27,36.66-27.61,22.49-.75,45-2.33,67.39-4.13a49.87,49.87,0,0,0,17.83-4.87q122.6-61,258.82-74.55c71.61-7,142.74-3.81,210.74,21.16,42.05,15.44,82.48,35.55,123,55,19.26,9.23,37.91,17.08,59.91,14.94,10.93-1.06,22.11.35,33.17.88,66.84,3.2,133,11.08,197.7,28.89,30.82,8.48,60.23,20.16,77.46,48.93,8.87,14.82,13.45,32.2,20,48.43q0,13.29,0,26.57c-1.3,6.09-2.71,12.16-3.87,18.27-3.21,16.93-11,24.24-28,26.06-3.67.39-7.32.93-11,1.31-18.83,2-37.67,3.9-57,5.9,14-63.74,5.29-98.39-34.58-126.89-34.87-24.93-73.44-27.77-110.24-5.41-50.84,30.89-61.83,77.12-43.2,132.15H2144.32c17.82-50.42,8.94-93.68-33.9-125.86-32.08-24.1-68.79-27.27-104.34-8.7-49.75,26-65,69-53.21,123-4.65,0-8.71.43-12.65-.07-24.87-3.17-49.71-6.62-74.58-9.83-17.47-2.26-30.12-11.36-38.21-26.78-4.75-9-9-18.33-13.45-27.51Zm279.58-97.28c3.25,2.89,9.12,3.94,13.81,3.95q171.6.31,343.2.16h33.56l8.18-20.14c-104.57-67.94-305-65.94-415.68-4C2082.6,758.44,2087.33,765.66,2093.55,771.2Z" transform="translate(-1805.98 -672.29)"/><path d="M2052.38,1015.9c-42.27,1.35-79.86-34.45-80.77-76.93-1-45.05,33.32-81,78.46-82.33,42.72-1.23,79.21,34.46,80.24,78.46C2131.32,978.36,2096.47,1014.49,2052.38,1015.9Z" transform="translate(-1805.98 -672.29)"/><path d="M2755.33,1015.55c-42.27,1.35-79.86-34.45-80.77-76.93-1-45.05,33.32-81,78.46-82.33,42.72-1.23,79.21,34.46,80.24,78.46C2834.27,978,2799.42,1014.15,2755.33,1015.55Z" transform="translate(-1805.98 -672.29)"/>
  </svg>
)

export default LuxeWagen
