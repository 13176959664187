import React from 'react'

import Stad from '../caricons/stad'
import Gezin from '../caricons/gezin'
import Cargo from '../caricons/cargo'
import Mono from '../caricons/mono'
import Bestel from '../caricons/bestel'
import Luxe from '../caricons/luxe'

export const getCarIcon = (volgnr, size) => {
  let returnValue = null
  switch (volgnr) {
    case 1:
      returnValue = (<Stad size={size} />)
      break
    case 2:
      returnValue = (<Gezin size={size} />)
      break
    case 3:
      returnValue = (<Cargo size={size} />)
      break
    case 4:
      returnValue = (<Mono size={size} />)
      break
    case 5:
      returnValue = (<Bestel size={size} />)
      break
    case 6:
      returnValue = (<Luxe size={size} />)
      break
    default:
      returnValue = (<Stad size={size} />)
      break
  }
  return returnValue
}
