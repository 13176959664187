import React, { Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  intro: {
    marginTop: 20,
    position: 'sticky',
    left: 0,
    padding: 16,
    borderBottom: '1px solid lightgrey'
  },
  title: {
    verticalAlign: 'center',
    fontSize: 18,
    '& strong': {
      marginRight: 8,
      marginTop: 2
    }
  },
  result: {
    marginBottom: 20
  },
  addButton: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.primary.dark }
  }
}))

const DelenEigenWagen = ({ language, labels, shareOwnCar, totalTime, totalKm, handleDeleteDeel, handleEditDeel, handleAddDeel}) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({smallScreen})

  return (
    <Fragment>
      <div className={classes.intro}>
        <span className={classes.title}>
          <strong>
            <span>{labels.titel_deeleigenwagen[language]}</span>
          </strong>
          <IconButton aria-label="expand row" size="small" onClick={handleAddDeel} className={classes.addButton}>
            <AddIcon />
          </IconButton>
        </span>
        <ReactMarkdown children={labels.intro_deeleigenwagen[language].replace('$bezetting', Math.round(100 - (totalTime/ 525600)*100)).replace('$kms', totalKm.toLocaleString('nl-BE'))} />
        {shareOwnCar.kms != 0 &&
          <div className={classes.result}>
            <ReactMarkdown children={labels.label_deeleigenwagen_resultaat[language].replace('$kms',shareOwnCar.kms.toLocaleString('nl-BE')).replace('$bedrag',`${shareOwnCar.prijs.toLocaleString('nl-BE')} €`)} />
          </div>
        }
      </div>
      <div>
      </div>
    </Fragment>
  )
}

export default DelenEigenWagen
