import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image
} from "@react-pdf/renderer"
import starIcon from '../../images/star.png'
// import logoA from '../../images/logo_autodelen.png'
import logoA from '../../images/nieuw_logo_kleur.png'
// import logoB from '../../images/logo_bxl.png'
import { theme1 } from '../../theme'
import fontregular from '../../fontfiles/Poppins-Regular.ttf'
import fontbold from '../../fontfiles/Poppins-Bold.ttf'
// import ReactMarkdown from 'react-markdown'

Font.register({
  family: 'Poppins',
  src: fontregular,
  fontWeight: 'normal'
})
Font.register({
  family: 'Poppins',
  src: fontbold,
  fontWeight: 'bold'
})
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 30,
    paddingBottom: 60,
    fontFamily: "Poppins",
  },
  header: {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  company: {
    fontSize: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: 105*0.5,
    // width: 229*0.5
  },
  star: {
    color: theme1.palette.primary.main,
    height: 10,
    width: 10
  },
  intro: {
    fontSize: 12,
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 10,
    color: theme1.palette.primary.main
  },
  subtitle: {
    color: theme1.palette.primary.main,
    fontSize: 14,
  },
  results: {
    // fontSize: 12,
    // lineHeight: 1.2,
    // backgroundColor: '#eee',
    // borderRadius: 5,
    // padding: 10,
    marginTop: 10,
    // marginBottom: 80,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0
    borderColor: '#ccc',
    // height: 1
  },
  tableHeaderRow: {
    margin: "auto",
    flexDirection: "row",
    flexShrink: 1,
    backgroundColor: theme1.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    paddingBottom: 3
  },
  tableRow: {
    minHeight: 30,
    margin: "auto",
    flexDirection: "row",
    // flexShrink: 1,
    paddingBottom: 3,
  },
  tableTextRow: {
    backgroundColor: '#fff',
    paddingBottom: 5,
    paddingTop: 10,
    borderStyle: "solid",
    borderColor: '#ccc',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  tableTextCell: {
    textAlign: 'left',
    fontSize: 10,
    paddingLeft: 3
  },
  tableCell: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 10,
    textAlign: 'center'
  },
  total: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: 'flex-end',
    fontSize: 12
  },
  savings: {
    color: '#34c4a5',
    fontWeight: 'bold'
  },
  electric: {
    marginLeft: 4,
    '& svg': {
      fill: theme1.palette.primary.main
    }
  },
  extraInfo: {
    marginTop: 45,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    marginTop: 100
  },
});

const getTableColStyle = (cols) => {
  return ({
    width: `${100 / cols}%`,
    overflow: 'hidden'
  })
}

const getTableHeaderColStyle = (cols) => {
  let style = {
    width: `${100 / cols}%`,
    overflow: 'hidden',
    minHeight: 30
  }
  if (cols > 6) {
    style.height = '100px'
  }
  return (style)
}

const getTableHeaderCellWrapperStyle = (cols) => {
  let style = {}
  if (cols > 6) {
    style.height = '100px'
    style.display = 'flex'
    style.justifyContent = 'center'
  }
  return (style)
}
const getTableHeaderCellStyle = (cols) => {
  if (cols > 6) {
    return ({
      fontSize: 10,
      transform:'rotate(-90deg)',
      transformOrigin: 'center center',
      width: '100px',
      paddingLeft: '10px',
      paddingBottom: '20px'
    })
  } else {
    return ({
      margin: "auto",
      marginTop: 5,
      fontSize: 10
    })
  }
}

const getTableCombiColStyle = (cols) => {
  return ({
    width: `${100 / cols}%`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: 30,
    height: '100%'
  })
}

const ResultsPdf = ({ language, labels, results, alternatives, selectedMotiefs, combis, resultOwnerCar, selectedAboForComp, shareOwnCar }) => {
  // if (results.length > 0) {
    // console.log('results', results);
    const nbrOfCols = selectedMotiefs.length + 2 + (resultOwnerCar != null ? 1 : 0)
    return (
      <Document>
        <Page style={styles.page} size="A4" wrap={true} orientation={selectedMotiefs.length >= 10 ? 'landscape' : 'portrait'}>
          <View style={styles.header} fixed>
            <View style={styles.company} >
              <Image
                style={styles.image}
                source={logoA}
              />
            </View>
{/*            <View style={styles.company} >
              <Image
                style={styles.image}
                source={logoB}
              />
            </View>
*/}
          </View>
          <View style={styles.intro} >
            <Text>{`${labels.titel_1[language]} ${(new Date()).toLocaleDateString(`${language}-BE`)}.`}</Text>
            <Text>{`${labels.titel_2[language]} ${results[0].jaarKmMotiefs != null ? results[0].jaarKmMotiefs : results[1].jaarKmMotiefs} ${labels.label_km_per_jaar[language]}.`}</Text>
          </View>

          <View style={styles.title} >
            <Text>{labels.titel_geselecteerde_aanbieders[language]}</Text>
          </View>

          <View style={styles.results}>
            <View style={styles.table}>
              {/* TableHeader */}
              <View style={styles.tableHeaderRow} fixed>
                <View style={getTableHeaderColStyle(nbrOfCols)}>
                  <View style={getTableHeaderCellWrapperStyle(nbrOfCols)}>
                    <Text style={getTableHeaderCellStyle(nbrOfCols)}>{labels.label_aanbieder[language]}</Text>
                  </View>
                </View>
                <View style={getTableHeaderColStyle(nbrOfCols)}>
                  <View style={getTableHeaderCellWrapperStyle(nbrOfCols)}>
                    <Text style={getTableHeaderCellStyle(nbrOfCols)}>{labels.label_totaal_ritten[language]}</Text>
                  </View>
                </View>

                {resultOwnerCar !=null ? (
                  <View style={getTableHeaderColStyle(nbrOfCols)}>
                    <View style={getTableHeaderCellWrapperStyle(nbrOfCols)}>
                      <Text style={getTableHeaderCellStyle(nbrOfCols)}>{labels.label_besparing[language]}</Text>
                    </View>
                  </View>
                  ) : null
                }
                
                {selectedMotiefs.map((sm, idx) =>
                  <View key={idx} style={getTableHeaderColStyle(nbrOfCols)}>
                    <View style={getTableHeaderCellWrapperStyle(nbrOfCols)}>
                      <Text style={getTableHeaderCellStyle(nbrOfCols)}>{sm.naam}</Text>
                    </View>
                  </View>
                )}
              </View>
              {/* TableContent */}
              {results.map((abo, rowidx) =>
                <View key={rowidx} style={[styles.tableRow, {backgroundColor: (rowidx % 2 === 0 ? '#eee' : '#fff') }]} wrap={false}>

                  <View style={getTableColStyle(nbrOfCols)}>
                    <Text style={styles.tableCell}>
                      {abo.id != null && selectedAboForComp.includes(abo.id) ? <Text><Image source={starIcon} style={styles.star} />{' '}</Text> : null}
                      {abo.cartype != null ? labels.label_eigen_wagen[language] : abo.naam}
                    </Text>
                  </View>
                  <View style={getTableColStyle(nbrOfCols)}>
                    <Text style={styles.tableCell}>{`${Math.round(abo.jaarPrijsMotiefs).toLocaleString('nl-BE')} €`}</Text>
                  </View>

                  {resultOwnerCar !=null ? (
                    <View style={getTableColStyle(nbrOfCols)}>
                      <Text style={[styles.tableCell, styles.savings]}>{resultOwnerCar.jaarPrijsMotiefs - abo.jaarPrijsMotiefs > 0 ? `+${Math.round(resultOwnerCar.jaarPrijsMotiefs - abo.jaarPrijsMotiefs).toLocaleString('nl-BE')} €` : ''}</Text>
                    </View>
                    ) : null
                  }

                  {selectedMotiefs.map((sm, idx) =>
                    <View key={idx} style={getTableColStyle(nbrOfCols)}>
                      <Text style={styles.tableCell}>{abo.motiefs[idx] != null ? `${Math.round(abo.motiefs[idx].jaarPrijs).toLocaleString('nl-BE')} €` : '-'}</Text>
                    </View>
                  )}
                </View>
              )} 


              {alternatives.length > 0 ? (
                <React.Fragment>
                  <View style={[styles.tableTextRow]}>
                    <Text style={[styles.tableTextCell, styles.subtitle]}>{`Alternatieven:`}</Text>
                  </View>
                  {alternatives.map((abo, rowidx) =>
                    <View key={rowidx} style={[styles.tableRow, {backgroundColor: (rowidx % 2 === 0 ? '#eee' : '#fff') }]} wrap={false}>
                      <View style={getTableColStyle(nbrOfCols)}>
                        <Text style={styles.tableCell}>
                          {abo.cartype != null ? labels.label_eigen_wagen[language] : abo.naam}
                        </Text>
                      </View>
                      <View style={getTableColStyle(nbrOfCols)}>
                        {/* <Text style={styles.tableCell}>{`${Math.round(abo.jaarPrijsMotiefs).toLocaleString('nl-BE')} €`}</Text> */}
                      </View>

                      {resultOwnerCar !=null ? (
                        <View style={getTableColStyle(nbrOfCols)}>
                          <Text style={[styles.tableCell, styles.savings]}></Text>
                        </View>
                        ) : null
                      }

                      {selectedMotiefs.map((sm, idx) =>
                        <View key={idx} style={getTableColStyle(nbrOfCols)}>
                          <Text style={styles.tableCell}>{abo.motiefs[idx] != null ? abo.motiefs[idx].otherAuto ? '-' : `${Math.round(abo.motiefs[idx].jaarPrijs).toLocaleString('nl-BE')} €` : '-'}</Text>
                        </View>
                      )}
                    </View>
                  )} 
                  </React.Fragment>
                ) : null
              }


              {combis.length > 0 ? (
                <React.Fragment>
                  <View style={[styles.tableTextRow]}>
                    <Text style={[styles.tableTextCell, styles.subtitle]}>{labels.titel_combinaties[language]}</Text>
                  </View>
                  {combis.map(({ selectedAbos, selectedOwnerCars, results, total }, rowidx) =>
                    <View key={rowidx} style={[styles.tableRow, {backgroundColor: (rowidx % 2 === 0 ? '#eee' : '#fff') }]} wrap={false}>
                      <View style={getTableCombiColStyle(nbrOfCols)}>
                        <Text style={styles.tableCell}>{` `}</Text>
                        <Text style={styles.tableCell}>{`${labels.label_combinatie[language]} ${rowidx + 1}`}</Text>
                      </View>
                      <View style={getTableCombiColStyle(nbrOfCols)}>
                        <Text style={styles.tableCell}>{` `}</Text>
                        <Text style={styles.tableCell}>{`${Math.round(total).toLocaleString('nl-BE')} €`}</Text>
                      </View>
                      {resultOwnerCar !=null ? (
                        <View style={getTableColStyle(nbrOfCols)}>
                          <Text style={[styles.tableCell, styles.savings]}>
                            {resultOwnerCar.jaarPrijsMotiefs - total > 0 ? `+${Math.round(resultOwnerCar.jaarPrijsMotiefs - total).toLocaleString('nl-BE')} €`: '-'}
                          </Text>
                        </View>
                        ) : null
                      }
                      {selectedMotiefs.map((sm, idx) =>
                        <View key={idx} style={getTableCombiColStyle(nbrOfCols)}>
                          <Text style={styles.tableCell}>
                            {selectedAbos[idx] != null ? selectedAbos[idx].naam
                              : selectedOwnerCars[idx] != null ?              
                                `${labels.label_eigen_wagen[language]}`
                              : ''}
                          </Text>
                          <Text style={styles.tableCell}>
                            {`${Math.round(results[idx]).toLocaleString('nl-BE')} €`}
                          </Text>
                        </View>
                      )}
                    </View>
                  )}
                  </React.Fragment>
                ) : null
              }

              {shareOwnCar.kms > 0 ? (
                <React.Fragment>
                  <View style={[styles.tableTextRow]}>
                    <Text style={[styles.tableTextCell, styles.subtitle]}>{labels.titel_deeleigenwagen[language]}</Text>
                    <Text style={styles.tableTextCell}>{labels.label_deeleigenwagen_resultaat[language].replace('$kms',shareOwnCar.kms.toLocaleString('nl-BE')).replace('$bedrag',`${shareOwnCar.prijs.toLocaleString('nl-BE')} €`)}</Text>
                  </View>
                  <View style={[styles.tableRow, {backgroundColor: '#eee'}]} wrap={false}>
                    <View style={getTableColStyle(nbrOfCols)}>
                      <Text style={styles.tableCell}>
                        {labels.titel_gedeeldeeigenwagen[language]}
                      </Text>
                    </View>
                    <View style={getTableColStyle(nbrOfCols)}>
                      <Text style={styles.tableCell}>{`${Math.round(resultOwnerCar.jaarPrijsMotiefs - shareOwnCar.prijs).toLocaleString('nl-BE')} €`}</Text>
                    </View>

                    {resultOwnerCar !=null ? (
                      <View style={getTableColStyle(nbrOfCols)}>
                        <Text style={[styles.tableCell, styles.savings]}>{`+${Math.round(shareOwnCar.prijs).toLocaleString('nl-BE')} €`}</Text>
                      </View>
                      ) : null
                    }

                    {selectedMotiefs.map((sm, idx) =>
                      <View key={idx} style={getTableColStyle(nbrOfCols)}>
                        <Text style={styles.tableCell}>{resultOwnerCar.motiefs[idx] != null ? `${Math.round(resultOwnerCar.motiefs[idx].jaarPrijs * ((resultOwnerCar.jaarPrijsMotiefs - shareOwnCar.prijs) / resultOwnerCar.jaarPrijsMotiefs) ).toLocaleString('nl-BE')} €` : '-'}</Text>
                      </View>
                    )}
                  </View>
                </React.Fragment>
                ) : null
              }

            </View>
          </View>

          <View style={styles.extraInfo}>
            <View style={styles.title} >
              <Text>{labels.titel_extra_info[language]}</Text>
            </View>
            <Text style={styles.tableTextCell}>{labels.tekst_extra_info[language]}</Text>
          </View>

          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />

        </Page>
      </Document>
    )
  // } else {
  //   return null
  // }
}

export default ResultsPdf
