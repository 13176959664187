import React, { useMemo, useState } from 'react'
import { Button, Container, Grid, List, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@material-ui/icons/Info'
import StepTitle from '../components/common/steptitle'
import TextInput from '../components/inputs/TextInput'
import axios from 'axios'
import { strapiUrl } from '../service'
import { v4 as uuidv4 } from 'uuid'
import { useEffect } from 'react'
import { isEmpty, isEmail, isPostalCode } from 'validator'
import { paths } from '../extras/paths'
import { flattenObject } from '../helper'

const useStyles = makeStyles(() => ({
  information: {
    display: 'flex',
    marginLeft: 10,
  },
  list: {
    marginRight: 10,
  },
}))

function getFormulierState() {
  const formData = sessionStorage.getItem('formulier')
  if (formData) {
    return JSON.parse(formData)
  } else
    return {
      voorNaam: '',
      achterNaam: '',
      email: '',
      postCode: '',
    }
}

export default function Formulier({ moduleIdx, labels, modules, language, results }) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const navigate = useNavigate()

  const [formData, setFormData] = useState(getFormulierState())

  useEffect(() => {
    sessionStorage.setItem('formulier', JSON.stringify(formData))
  }, [JSON.stringify(formData)])

  function handleFormData(e) {
    setFormData((prevValue) => ({
      ...prevValue,
      [e.name]: e.value,
    }))
  }

  async function handleFormulier() {
    try {
      var sessionData = {}
      for (var i = 0; i < sessionStorage.length; i++) {
        var key = sessionStorage.key(i)
        var value = sessionStorage.getItem(key)
        if (key !== 'results') sessionData[key] = value
      }
      const response = await axios.post(`${strapiUrl}/formuliers`, {
        data: {
          uuid: uuidv4(),
          resultaat: results,
          session_storage: sessionData,
        },
      })
      return flattenObject(response.data) 
    } catch (error) {
      console.log('There was an error posting the data:', error)
    }
  }

  async function handleMailerLite(uuid) {
    const API_KEY = process.env.REACT_APP_MAILERLITE_TOKEN

    var autoOption = 'DEELAUTO'

    if (results[0].hasOwnProperty('carId')) {
      autoOption = 'EIGENAUTO'
    }

    const GROUPS = {
      fr: {
        DEELAUTO: '103013926024774785',
        EIGENAUTO: '103013915206616911',
      },
      nl: {
        DEELAUTO: '103013901769115234',
        EIGENAUTO: '103013884696200194',
      },
    }

    const GROUP_ID = GROUPS[language][autoOption]

    const endpoint = 'https://connect.mailerlite.com/api/subscribers'

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${API_KEY}`,
    }

    const link = language === 'nl' ? `https://bespaarmet.autodelen.net` : `https://calculateur.autodelen.net`
    const resultLink =  `${link}/${language === 'nl' ? 'resultaten' : 'resultats'}/${uuid}`

    const data = {
      email: formData.email,
      fields: {
        last_name: formData.achterNaam,
        voornaam: formData.voorNaam,
        z_i_p: formData.postCode,
        resultlink: resultLink,
      },
      groups: [GROUP_ID],
    }

    axios
      .post(endpoint, data, { headers: headers })
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  async function addOrUpdateSubscriber() {
    const formulierResult = await handleFormulier()
    await handleMailerLite(formulierResult.uuid)
    navigate(`/${paths.resultaten[language]}`)
  }

  const disabled = useMemo(() => {
    return (
      isEmpty(formData.voorNaam) ||
      isEmpty(formData.achterNaam) ||
      !isEmail(formData.email) ||
      !isPostalCode(formData.postCode, 'BE')
    )
  }, [JSON.stringify(formData)])

  const texts = {
    title: {
      nl: 'Je bent er bijna!',
      fr: `C'est presque fini!`,
    },
    text: {
      nl: 'Vul je gegevens in om je resultaten te zien.',
      fr: 'Introduisez vos coordonnées pour connaître le résultat.',
    },
    fields: {
      firstName: {
        nl: 'Voornaam',
        fr: 'Prénom',
      },
      lastName: {
        nl: 'Naam',
        fr: 'Nom',
      },
      email: {
        nl: 'E-mailadres',
        fr: 'Adresse électronique',
      },
      zipCode: {
        nl: 'Postcode',
        fr: 'Code postal',
      },
      disclaimer: {
        nl: (
          <Typography variant='body2'>
            Hierbij ga ik akkoord met het <a href='https://www.autodelen.net/nl/privacy/'>privacybeleid.</a>
          </Typography>
        ),
        fr: (
          <Typography variant='body2'>
            En soumettant le formulaire, j'accepte la{' '}
            <a href='https://www.autodelen.net/fr/privacy/'>politique de protection de la vie privée.</a>
          </Typography>
        ),
      },
    },
    button: {
      nl: 'Toon mijn resultaten',
      fr: 'Vers le résultat',
    },
  }

  return (
    <Container maxWidth='lg'>
      <StepTitle labels={labels} modules={modules} language={language} propActiveStep={moduleIdx} />

      <div className={classes.information}>
        <Typography variant='h4'>{texts.title[language]}</Typography>
      </div>
      <div className={classes.information}>
        <Typography variant='body1'>{texts.text[language]}</Typography>
      </div>

      <Grid container>
        <Grid item xs={12} lg={6}>
          <List>
            <TextInput
              name={'voorNaam'}
              label={texts.fields.firstName[language]}
              value={formData.voorNaam}
              onChange={handleFormData}
            />
            <TextInput
              name={'achterNaam'}
              label={texts.fields.lastName[language]}
              value={formData.achterNaam}
              onChange={handleFormData}
            />
          </List>
        </Grid>
        <Grid item xs={12} lg={6}>
          <List>
            <TextInput
              name={'email'}
              label={texts.fields.email[language]}
              value={formData.email}
              onChange={handleFormData}
            />
            <TextInput
              name={'postCode'}
              label={texts.fields.zipCode[language]}
              value={formData.postCode}
              onChange={handleFormData}
            />
          </List>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10, marginLeft: 15 }}>
          {texts.fields.disclaimer[language]}
          <Button
            style={{ marginTop: 15 }}
            color='primary'
            variant='contained'
            onClick={addOrUpdateSubscriber}
            disabled={disabled}
          >
            {texts.button[language]}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
