import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
// import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { paths } from './../../extras/paths';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 0px 50px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepper: {
    padding: 0,
    // width: '100%',
    backgroundColor: 'transparent',
    '& .MuiStepIcon-text': {
      fill: '#fff'
    }
  },
  step: {
    wordBreak: 'break-word',
    fontSize: 'calc(12px + 0.2vw)'
  },
  completedStep: {
    color: theme.palette.primary.main
    // fontSize: '0.8rem'
  },
  activeStep: {
    fontSize: 'calc(12px + 1.2vw)'
    // fontWeight: 'bold'
  }
}))

const StepTitle = ({ labels, modules, language, propActiveStep }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  let navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  // const [completed, setCompleted] = useState({})

  const handleStep = (step) => () => {
    setActiveStep(step)
    if (step === 0) {
      navigate(`/${paths.motieven[language]}`)
    } else if (step === 1) {
      navigate(`/${paths.eigenwagen[language]}`)
    } else if (step === 2) {
      navigate(`/${paths.resultaten[language]}`)
    }
  }

  useEffect(() => {
    setActiveStep(propActiveStep)
  }, [propActiveStep])

  return (
    <div className={classes.container}>
      {/* <Typography variant="h4" color="primary">{title}</Typography> */}
      <Stepper className={classes.stepper} nonLinear activeStep={activeStep} alternativeLabel={!matches} >
        {modules.map((module, index) => {
          return (
            <Step key={module}>
              <StepButton onClick={handleStep(index)} completed={index < activeStep} disabled={index >= activeStep} color="primary">
                <Typography variant="subtitle2"
                  className={classes.step + (index <= activeStep ? ' ' + classes.completedStep : '') + (index === activeStep ? ' ' + classes.activeStep : '')}
                >{labels[module].titel[language]}</Typography>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  )
}

export default StepTitle
