import React from 'react'
import ReactMarkdown from 'react-markdown'
import Container from '@material-ui/core/Container'

const Privacy = ({ text }) => {
  return (
    <Container maxWidth="lg" style={{ marginTop: '10vh' }}>
      <ReactMarkdown children={text} />
    </Container>
  )
}

export default Privacy
