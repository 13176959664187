import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ReactMarkdown from 'react-markdown'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import start from '../images/start.svg'
import persona from '../images/persona.svg'
import { paths } from '../extras/paths'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${start})`,
    backgroundSize: 'contain',
    // minWidth: '100%',
    // minHeight:
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  intro: {
    paddingTop: '35vh',
    width: '100%',
    height: '80vh',
    margin: 'auto',
    textAlign: 'center',
    '& h3': {
      fontSize: '3vh' 
    }
  },
  information: {
    paddingTop: '2vh',
    '& p': {
      display: 'inline',
      fontSize: '1.5vh'
    }
  },
  start: {
    paddingTop: '2vh',
    textAlign: 'center',
    '& a.MuiButton-containedPrimary': {
      color: '#fff'
    },
  },
  persona: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -40,
    '& >img': {
      width: '50%',
      minWidth: 300,
    }
  },
  introSmall: {
    paddingTop: '15vh',
    width: '100%',
    // height: '80vh',
    margin: 'auto',
    textAlign: 'center',
    '& h3': {
      fontSize: '3vh' 
    }
  }
}))

const Start = ({ labels, language }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      {smallScreen ? (
        <Container>
          <span className={classes.persona}>
            <img src={persona} />
          </span>
          <div className={classes.introSmall}>
            <Typography variant="h3">
              {labels.titel[language]}
            </Typography>
            <div className={classes.information}>
              <Typography variant="body1">
                <ReactMarkdown children={labels.intro[language]} />
              </Typography>
            </div>
            <div className={classes.start}>
              <Button disableElevation={true} variant="contained" size="large" color="primary" component={Link} to={paths.motieven[language]}>{labels.label_start[language]}</Button>
            </div>
          </div>
        </Container>
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.intro}>
            <Typography variant="h3">
              {labels.titel[language]}
            </Typography>
            <div className={classes.information}>
              <Typography variant="body1">
                <ReactMarkdown children={labels.intro[language]} />
              </Typography>
            </div>
            <div className={classes.start}>
              <Button disableElevation={true} variant="contained" size="large" color="primary" component={Link} to={paths.motieven[language]}>{labels.label_start[language]}</Button>
            </div>
          </div>
        </Container>
      )}
    </Fragment>
  )
}

export default Start
