import React, { useState, useRef, useEffect } from 'react'
import OwnerCarEdit from './ownerCarEdit'
import { getCarIcon } from '../../caricons/index'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import TextField from '@material-ui/core/TextField'
// import Switch from '@material-ui/core/Switch'
import DeleteIcon from '@material-ui/icons/HighlightOff'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import Dialog from '@material-ui/core/Dialog'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined'
// import EvStationOutlinedIcon from '@material-ui/icons/EvStationOutlined'
// import PlugIcon from '../../icons/plug'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined'
// import LooksOneOutlinedIcon from '@material-ui/icons/LooksOneOutlined'
// import RvHookupOutlinedIcon from '@material-ui/icons/RvHookupOutlined'
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
// import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined'
import SpeedIcon from '../../icons/speed'
// import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined'
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
// import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
// import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  car: {
    padding: 20,
    paddingTop: 10,
    // height: 315
  },
  editButton: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      color: '#fff',
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10
  },
  title: {
        paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,

    display: 'flex',
    justifyContent: 'space-between',
    '& h6': {
      fontWeight: 'bold'
    },
    '& svg:nth-child(1)': {
      fill: theme.palette.text.primary
    },
    '& svg:nth-child(2)': {
      fill: theme.palette.primary.main
    },
    '& svg:nth-child(2):hover': {
      cursor: 'pointer'
    }
  },
  subtitle: {
    '& svg': {
      stroke: theme.palette.text.primary
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    '& svg': {
      marginRight: 10,
      fill: theme.palette.secondary.main
    },
    '& >:nth-child(2)': {
      marginRight: 10
    },
    '& >:nth-child(3)': {
      fontWeight: 'bold'
    }
  },
  modaltitle: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
  },
  submodaltitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.text.primary,
      marginRight: 25,
    }
  },
  modalContent: {
    padding: 20,
    minWidth: 250
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 10
  },
  number: {
    width: 100
  },
  modalButtonContainer: {
    marginTop: 30,
    // padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '&>button': {
      marginLeft: 10,
      color: '#fff'
    }
  }
}))

// const NON_DIGIT = '/[^d]/g'
// const makeItInt = (value) => {
//   const parsedValue = parseInt(value.replace(NON_DIGIT, ''))
//   if (parsedValue >= 0) {
//     return parsedValue
//   } else {
//     return 0
//   }
// }

const OwnerCarSelected = ({language, labelsModule, ownerCar, handleDeSelect, idx, handleUpdateOwnerCar}) => {
  const classes = useStyles()
  // const { auto, nieuwprijs, nieuwprijselektrisch, leeftijd, kmstand, verzekering, verzekeringelektrisch, pechhulp, garage, onderhoud, onderhoudelektrisch, herstellingen, herstellingenelektrisch, belasting, belastingelektrisch, keuring, carwash, electric } = ownerCar
  const { auto, nieuwprijs, nieuwprijselektrisch, leeftijd, kmstand, electric } = ownerCar
  const [open, setOpen] = useState(false)
  let myRef = useRef()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop -300})
  }, [])

  return (
    <div ref={myRef}>
      <OwnerCarEdit
        language={language}
        labelsModule={labelsModule}
        open={open}
        adding={false}
        handleClose={handleClose}
        handleUpdateOwnerCar={handleUpdateOwnerCar}
        ownerCar={ownerCar}
      />
      <Paper className={classes.car}>
        <div className={classes.title}>
          <span className={classes.subtitle}>
            {getCarIcon(auto.volgnr, 'md')}
            <Typography variant="h6">{auto.labels[0][language]}</Typography>
          </span>
          <DeleteIcon onClick={handleDeSelect(idx)} />
        </div>
        
        <div className={classes.list}>
          <span className={classes.item}><LocalOfferOutlinedIcon /><span>{labelsModule.label_nieuwprijs[language]}:</span><span>{`${(electric ? nieuwprijselektrisch : nieuwprijs).toLocaleString('nl-BE')} €`}</span></span>
          <span className={classes.item}><TimelapseOutlinedIcon /><span>{labelsModule.label_leeftijd[language]}:</span><span>{`${leeftijd} ${labelsModule.label_jaar[language]}`}</span></span>
          <span className={classes.item}><SpeedIcon size="24" /><span>{labelsModule.label_kilometerstand[language]}:</span><span>{`${kmstand.toLocaleString('nl-BE')} ${labelsModule.label_km[language]}`}</span></span>
        </div>
        <div className={classes.editButton}>
          <Button variant='contained' color="primary" onClick={handleOpen}>{labelsModule.knop_aanpassen[language]}</Button>
        </div>
      </Paper>
    </div>
  )
}

export default OwnerCarSelected
