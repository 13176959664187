import React from 'react'

const sizes = {
  sm: 36,
  md: 64
}

const strokeWidths = {
  sm: 24,
  md: 20
}

const Cargo = ({ size }) => (
  <svg width={`${sizes[size]}px`} height={`${sizes[size]}px`} viewBox="0 0 809.87 342.21" style={{fill:'none', strokeWidth: strokeWidths[size]}}>
    <path d="M1984,929.75c2.46-2.16,4.61-5.48,7.44-6.24,6.31-1.69,6.6-5.66,6.71-11,1-47.29,3.84-94.42,12.23-141.07,4.44-24.68,9.27-49.3,22.47-71.11,2.17-3.59,7.12-6.83,11.28-7.63a299.85,299.85,0,0,1,36.06-4.58c91.13-6.17,182.2-7.46,273,4.37,38.17,5,74.94,14.38,108,35.3q65.09,41.22,130.39,82.09a37.81,37.81,0,0,0,12.68,5.07c39.29,7.45,78.58,14.67,116.54,27.82,15.3,5.3,27.33,13.62,35.52,28,6.46,11.3,14.3,21.8,21.53,32.66q0,11.63,0,23.26c-2.52,2-7,3.94-7.2,6.14-.64,9.17-2.22,19.3.91,27.39,2.7,7-.4,7.34-4.15,8.13-8.57,1.81-17.26,3.15-26,4.16-13.75,1.6-27.56,2.78-41.62,4.17,10.63-53.61-26.6-86.69-59.44-91.37-19-2.7-36.79.94-52.31,12-26.78,19.1-35.75,45.88-30,78.33l-365.74-4.38c6.21-47.36-24.61-80.46-60.81-86-18.93-2.88-36.66,1.16-52.26,12.16-24.51,17.29-33.62,41.93-31.34,71.12-3.16,0-5.49.15-7.79,0-9-.67-18-1.23-27-2.21-22.81-2.49-22.8-2.57-28.74-24.23-.13-.49-.23-1-.34-1.47ZM2489.4,801.16c1.82-5.29,4.48-11.09,8.56-14.61,6.54-5.64,7.71-14.86-.16-18.84-18.12-9.16-36.29-18.56-55.29-25.55-24.81-9.12-51-12.74-77.47-13.71-10.74-.39-13.66,2.65-12,13.36,3.41,21.67,7.18,43.27,10.78,64.91.81,4.88,3.17,8.35,8.48,8.36,38.47.07,76.94,0,116.82,0C2489.14,809.76,2488.07,805,2489.4,801.16Z" transform="translate(-1975.99 -675.51)"/><path d="M2120.86,1009.7c-27.67.66-52.16-22.89-52.86-50.83-.74-29.74,21.88-53.36,52-54.29,28-.86,52,22.51,52.84,51.4S2150.34,1009,2120.86,1009.7Z" transform="translate(-1975.99 -675.51)"/><path d="M2629.78,1009.69c-27.51,1-52.32-22.57-53.18-50.5-.93-29.93,22.28-54.15,52.35-54.64,28.22-.46,51.79,22.79,52.49,51.79C2682.13,985,2659.21,1008.65,2629.78,1009.69Z" transform="translate(-1975.99 -675.51)"/>
  </svg>
)

export default Cargo
