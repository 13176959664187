import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const bosgroen = '#0d8174'
const groen = '#68bea5'
const groenblauw = '#199bb1'
const vuilgroen = '#5f9f9f'

const grijs = '#54697a'
const blauw = '#4682b4'

const geeloranje = '#f0be3b'

const grasgroen = "#a0af22"

const heelbleekgroen = '#f1f2e0'
const bleekgroen = '#e7eaca'

const nieuwblauw = '#0097a9'
const nieuwlichtblauw = '#D6EAE9'
const nieuwheellichtblauw = '#e5f4f4'

// A custom theme for this app
let theme1 = createMuiTheme({
  typography: {
    // fontFamily: "Source Sans Pro",
    fontFamily: "Poppins",
    // h1: {
    //   fontFamily: "Reem Kufi"
    // }
    body1: {
      // fontSize: 20,
    },
  },
  palette: {
    primary: {
      // main: grasgroen,
      main: nieuwblauw,
      // light: bleekgroen,
      light: nieuwlichtblauw,
      superlight: nieuwheellichtblauw,
      dark: grijs
    },
    secondary: {
      main: geeloranje
    },
    text: {
      primary: grijs,
      secondary: grijs
      // accent: blauw
    },
    background: {
      // default: heelbleekgroen
      default: '#fff'
    },
    motief: {
      one: bosgroen,
      two: groen,
      three: groenblauw,
      four: vuilgroen,
      five: blauw
    }
  }
});

theme1 = responsiveFontSizes(theme1)

export { theme1 }
