import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useStyles as useStylesHeader } from '../resultaten/tableheader'
import { useTheme } from '@material-ui/core/styles'

const ResultatenTableHeaderSimple = ({ labels, language, resultOwnerCar, selectedMotiefs }) => {
  const classes = useStylesHeader()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
        <TableHead>
          <TableRow className={classes.headerrow}>
            <TableCell key='a' className={classes.tableheader + ' ' + classes.sticky} />
            <TableCell key='b' className={classes.tableheader + ' ' + classes.sticky} />
            <TableCell key='c' align="center" className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)}>
              {labels.label_totaal_ritten[language]}
            </TableCell>
            {resultOwnerCar !=null ? (
              <TableCell key='d' align="center" className={classes.tableheader + (smallScreen ? '' : ' ' + classes.sticky)}>
                {labels.label_besparing[language]}
              </TableCell>
              ) : null
            }
            {selectedMotiefs.map((sm, idx) =>
              <TableCell key={idx} className={classes.tableheader} align="center">
                <span className={classes.motiefHeader}>{sm.naam}</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
  )
}

export default ResultatenTableHeaderSimple
