import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
// import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
// import { getFormulas, getMotiefs, getAanbieders } from '../service'
// const axios = require('axios')

const useStyles = makeStyles({
  formControl: {
    margin: 20,
    minWidth: 120,
  },
  container: {
    padding: 20
  },
  table: {
    minWidth: 650,
  },
  fff: {
    background: '#fff'
  },
  eee: {
    background: '#eee'
  },
  ddd: {
    background: '#ddd'
  }
})

const Formules = ({ aanbieders, abos }) => {
  const classes = useStyles()
  
  const [selectedAanbieder, setSelectedAanbieder] = useState(null)

  const handleChangeAanbieder = (event) => {
    // console.log("handleChangeAanbieder", event.target.value);
    setSelectedAanbieder(aanbieders[event.target.value])
  }

  return (
    <div className={classes.container} style={{ marginTop: '10vh' }}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Aanbieder</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedAanbieder == null ? '' : selectedAanbieder}
          onChange={handleChangeAanbieder}
        >
          <MenuItem value={null}>alle</MenuItem>
          {
            aanbieders.map(({ naam }, idx) => 
              <MenuItem value={idx}>{naam}</MenuItem>
            )
          }
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.fff}>Formule</TableCell>
              <TableCell align="right" className={classes.fff}>Abo</TableCell>
              {/* <TableCell align="right">Auto</TableCell> */}
              <TableCell align="right" className={classes.eee}>KM-max-km</TableCell>
              <TableCell align="right" className={classes.eee}>KM-km-eenheid</TableCell>
              <TableCell align="right" className={classes.eee}>KM-prijs-per-km</TableCell>

              {/* <TableCell align="right" className={classes.ddd}>U-start-uur</TableCell> */}
              {/* <TableCell align="right" className={classes.ddd}>U-eind-uur</TableCell> */}
              <TableCell align="right" className={classes.ddd}>T-eenheid</TableCell>
              <TableCell align="right" className={classes.ddd}>T-duur</TableCell>
              <TableCell align="right" className={classes.ddd}>T-prijs</TableCell>
              <TableCell align="right" className={classes.ddd}>T-moment</TableCell>
              <TableCell align="right" className={classes.ddd}>T-weekend</TableCell>
              <TableCell align="right" className={classes.ddd}>T-stil-stand</TableCell>
              <TableCell align="right" className={classes.ddd}>T-prijs-per-km</TableCell>

              <TableCell align="right" className={classes.fff}>Start-prijs-rit</TableCell>
              <TableCell align="right" className={classes.fff}>Minimum-prijs-rit</TableCell>

              <TableCell align="right" className={classes.fff}>Brandstof</TableCell>

              <TableCell align="right" className={classes.eee}>KM-limiet-duur-eenheid</TableCell>
              <TableCell align="right" className={classes.eee}>KM-limiet-max-duur</TableCell>
              <TableCell align="right" className={classes.eee}>KM-limiet-max-km</TableCell>
              <TableCell align="right" className={classes.eee}>KM-limiet-km-eenheid</TableCell>
              <TableCell align="right" className={classes.eee}>KM-limiet-prijs-per-km</TableCell>

              <TableCell align="right" className={classes.ddd}>T-limiet-periode</TableCell>
              <TableCell align="right" className={classes.ddd}>T-limiet-periode-eenheid</TableCell>
              <TableCell align="right" className={classes.ddd}>T-limiet-aantal-min</TableCell>
              <TableCell align="right" className={classes.ddd}>T-limiet-aantal-max</TableCell>
              <TableCell align="right" className={classes.ddd}>T-limiet-aantal-eenheid</TableCell>
              <TableCell align="right" className={classes.ddd}>T-limiet-prijs-extra-uur</TableCell>

              <TableCell align="right" className={classes.fff}>Abo-freq</TableCell>
              <TableCell align="right" className={classes.fff}>Abo-prijs</TableCell>
              <TableCell align="right" className={classes.fff}>Krediet</TableCell>
              
              <TableCell align="right" className={classes.fff}>Pre-paid-bedrag</TableCell>
              <TableCell align="right" className={classes.fff}>Pre-paid-korting</TableCell>
              
              <TableCell align="right" className={classes.fff}>Waar-borg</TableCell>
              <TableCell align="right" className={classes.fff}>Instap-kost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {abos.filter(({aanbieder}) => selectedAanbieder == null || (aanbieder.id === selectedAanbieder.id)).map((abo) =>
              abo.formules.map((row) => (
                <TableRow key={row.naam}>
                <TableCell component="th" scope="row">{`${row.naam}`}</TableCell>
                <TableCell align="right">{abo.naam}</TableCell>
                {/* <TableCell align="right">{row.auto.beschrijving}</TableCell> */}
                <TableCell align="right" className={classes.eee}>{row.kmprijzen.map((kmp) => <div>{kmp.maxkm || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.kmprijzen.map((kmp) => <div>{kmp.kmeenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.kmprijzen.map((kmp) => <div>{kmp.prijsperkm || 0}</div>)}</TableCell>

                {/* <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.startuur || "-"}</div>)}</TableCell> */}
                {/* <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.einduur || "-"}</div>)}</TableCell> */}
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.duureenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.duur || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.prijs == null ? "-" : up.prijs}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.moment || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.weekends || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.stilstand ? 'ja' : "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdstarieven.map((up) => <div>{up.prijsperkm || "-"}</div>)}</TableCell>

                <TableCell align="right">{row.startritprijs || "-"}</TableCell>
                <TableCell align="right">{row.minimumprijsrit || "-"}</TableCell>

                <TableCell align="right">{row.brandstof ? 'ja' : "-"}</TableCell>

                <TableCell align="right" className={classes.eee}>{row.afstandsbeperkingen.map((ab) => <div>{ab.duureenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.afstandsbeperkingen.map((ab) => <div>{ab.maxduur || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.afstandsbeperkingen.map((ab) => <div>{ab.maxkm || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.afstandsbeperkingen.map((ab) => <div>{ab.kmeenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.eee}>{row.afstandsbeperkingen.map((ab) => <div>{ab.prijsperkm || 0}</div>)}</TableCell>

                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.periodeaantal || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.periodeeenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.minaantal || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.maxaantal || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.maxeenheid || "-"}</div>)}</TableCell>
                <TableCell align="right" className={classes.ddd}>{row.tijdsbeperkingen.map((tb) => <div>{tb.extraprijsperuur || "-"}</div>)}</TableCell>
                
                <TableCell align="right">{abo.frequentie || "-"}</TableCell>
                <TableCell align="right">{abo.prijs || "-"}</TableCell>
                <TableCell align="right">{abo.isKrediet ? "ja" : "-"}</TableCell>

                <TableCell align="right">{row.prepaid ? row.prepaid.bedrag : "-"}</TableCell>
                <TableCell align="right">{row.prepaid ? row.prepaid.korting : "-"}</TableCell>
                
                <TableCell align="right">{row.waarborg || "-"}</TableCell>
                <TableCell align="right">{row.instapkost || "-"}</TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Formules
