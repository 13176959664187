import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined'
// import EvStationOutlinedIcon from '@material-ui/icons/EvStationOutlined'
import PlugIcon from '../../icons/plug'
import { getCarIcon } from '../../caricons/index'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  car: {
    padding: 20,
    paddingTop: 10,
    // height: 280,
    backgroundColor: 'rgba(244,244,244,1)',
    color: props => props.disabled ? '#aaa' : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: props => !props.disabled ? theme.palette.primary.main : 'rgba(244,244,244,1)',
      color: props => !props.disabled ? '#fff' : '#aaa',
      cursor: props => !props.disabled ? 'pointer' : 'inherit',
      '& svg': {
        fill: props => !props.disabled ? '#fff' : '#aaa'
      },
      '&>:nth-child(1) svg': {
        stroke: props => !props.disabled ? '#fff' : '#aaa'
      }
    },
  },
  title: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
    '& svg': {
      stroke: props => !props.disabled ? theme.palette.text.primary : '#aaa'
    },
  },
  description: {
    height: 155,
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '6px 0',
  },
  caricon: {
    marginRight: 10,
    fill: props => !props.disabled ? theme.palette.text.primary : '#aaa',
    alignSelf: 'flex-start'
  },
  plus: {
    alignSelf: 'flex-end',
    fill: props => !props.disabled ? theme.palette.primary.main : '#aaa'
  }

}))

const OwnerCar = ({language, labelsModule, ownerCar, handleSelect, disabled}) => {
  const classes = useStyles({disabled})
  const { id, auto } = ownerCar
  return (
    <Paper elevation={1} className={classes.car} onClick={disabled ? null : handleSelect(id)}>
      <div className={classes.title}>
        {getCarIcon(auto.volgnr, 'md')}
        <Typography variant="h6">{auto.labels[0][language]}</Typography>
      </div>
      <div className={classes.description}>
        <div>
          <span className={classes.item}><GroupOutlinedIcon className={classes.caricon} /><span></span><span>{`${auto.plaatsen} ${labelsModule.label_personen[language]}`}</span></span>
          <span className={classes.item}><LocalGasStationOutlinedIcon className={classes.caricon} /><span></span><span>{`${labelsModule.label_bvb[language]} ${auto.benzinevoorbeeld || '-'}`}</span></span>
          <span className={classes.item}><PlugIcon className={classes.caricon} /><span></span><span>{`${labelsModule.label_bvb[language]} ${auto.elektrischvoorbeeld || '-'}`}</span></span>
        </div>
        <ControlPointIcon className={classes.plus} />
      </div>
    </Paper>
  )
}

export default OwnerCar
