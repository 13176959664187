export const paths = {
    motieven: {
        nl: 'motieven',
        fr: 'deplacements'
    },
    eigenwagen: {
        nl: 'eigenwagen',
        fr: 'proprevoiture'
    },
    resultaten: {
        nl: 'resultaten',
        fr: 'resultats'
    }
}   

export const frenchToDutchPaths = {
    deplacements: 'motieven',
    proprevoiture: 'eigenwagen',
    resultats: 'resultaten',
    formulier: 'formulier',
    end: 'end'
}

export const dutchToFrenchPaths = {
    motieven: 'deplacements',
    eigenwagen: 'proprevoiture',
    resultaten: 'resultats',
    formulier: 'formulier',
    end: 'end'
}