import React, { Fragment } from 'react'
import { motiefKleur } from '../../helper'
import ReactMarkdown from 'react-markdown'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ResultatenTableHeaderSimple from '../resultaten/tableheader-simple'

const useStyles = makeStyles((theme) => ({
  intro: {
    position: 'sticky',
    left: 0,
    padding: 16,
    borderBottom: '1px solid lightgrey'
  },
  title: {
    verticalAlign: 'center',
    fontSize: 18,
    '& strong': {
      marginRight: 8,
      marginTop: 2
    }
  },
  table: {
    tableLayout: 'fixed'
  },  
  row: {
    backgroundColor: props => props.grey ? '#eee' : theme.palette.primary.superlight,
    '& td':{
      padding: '4px 16px'
    },
    '& td:nth-child(1)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      width: 57,
      left: 0,
      padding:13
    },
    '& td:nth-child(2)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      // whiteSpace: props => props.smallScreen ? 'normal' : 'inherit',
      width: 140,
      left: 57
    },
    '& td:nth-child(3)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      fontWeight: 'bold',
      width: 90,
      left: 197
    },
    '& td:nth-child(4)': {
      backgroundColor: props => props.grey ? '#eee' : '#fff',
      color: 'rgb(52,196,165)',
      fontWeight: 'bold',
      width: 90,
      left: 287
    },
    '& td:nth-child(n+5)': {
      fontWeight: 500,
      paddingLeft: 3,
      paddingRight: 3,
      wordBreak: 'break-word',
      width: 82
    }    
  },
  sticky: {
    position: 'sticky'
  },
  combicellFirst: {
    '&:hover':  {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      fontWeight: 'bold'
    }    
  },
  combicell: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    whiteSpace: 'normal',
  },
  addButton: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.primary.dark }
  }
}))

const CombiTable = ({ labels, language, combis, selectedMotiefs, resultOwnerCar, priceView, totalKm, handleDeleteCombi, handleEditCombi, handleAddCombi }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({smallScreen, nbrCols: selectedMotiefs.length + 4})

  const onClickDelete = (uuid) => () => {
    handleDeleteCombi(uuid)
  } 

  const onClickEdit = (uuid) => () => {
    handleEditCombi(uuid)
  } 

  return (
    <Fragment>
      <div className={classes.intro}>
        <span className={classes.title}>
          <strong>
            <span>{labels.titel_combinaties[language]}</span>
          </strong>
          <IconButton aria-label="expand row" size="small" onClick={handleAddCombi} className={classes.addButton}>
            <AddIcon />
          </IconButton>
        </span>
        <ReactMarkdown children={labels.tekst_combinaties[language]} />
      </div>
      <Table className={classes.table} >
        {combis.length > 0 &&
          <ResultatenTableHeaderSimple
            labels={labels}
            language={language}
            resultOwnerCar={resultOwnerCar}
            selectedMotiefs={selectedMotiefs}
          />
        }
        <TableBody>
          {combis.map(({ total, selectedAbos, selectedOwnerCars, results, uuid }, rowidx) =>
            <TableRow key={rowidx} className={classes.row}>
              <TableCell key='a' className={classes.sticky}>
                <IconButton size='small' onClick={onClickDelete(uuid)} ><DeleteOutlineOutlinedIcon /></IconButton>
              </TableCell>
              <TableCell key='b' className={classes.sticky}>
                <span className={classes.combicellFirst} onClick={onClickEdit(uuid)}>
                  {`${labels.label_combinatie[language]} ${rowidx + 1}`}
                </span>
              </TableCell>
              <TableCell key='c' className={smallScreen ? '' : ' ' + classes.sticky} align="center">
                <span className={classes.combicell}>
                  <span>
                    {priceView === 'y'
                      ? `${Math.round(total).toLocaleString('nl-BE')} €`
                      : `${(total/ totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                    }
                  </span>
                </span>
              </TableCell>
              {resultOwnerCar !=null ? (
                <TableCell key='d' className={smallScreen ? '' : ' ' + classes.sticky} align="center">
                  {resultOwnerCar.jaarPrijsMotiefs - total > 0
                    ? (priceView === 'y'
                        ? `+${Math.round(resultOwnerCar.jaarPrijsMotiefs - total).toLocaleString('nl-BE')} €`
                        : `+${((resultOwnerCar.jaarPrijsMotiefs - total) / totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                      )
                    : '-'
                  }
                </TableCell>
                ) : null
              }                         
              {selectedMotiefs.map((sm, idx) =>
                <TableCell key={idx} align="center" style={{ color: motiefKleur(sm.soort, theme) }}>
                  <span className={classes.combicell}>
                    <span>
                      {selectedAbos[idx] != null ? selectedAbos[idx].naam
                      : selectedOwnerCars[idx] != null ?              
                        `${labels.label_eigen_wagen[language]}`
                      : ''}
                    </span>
                    <span>
                      {priceView === 'y'
                        ? `${Math.round(results[idx]).toLocaleString('nl-BE')} €`
                        : `${(results[idx] / totalKm).toFixed(2).toLocaleString('nl-BE')} €`
                      }
                    </span>
                  </span>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  )
}

export default CombiTable
