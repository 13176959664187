import React from 'react'

const sizes = {
  sm: 36,
  md: 64
}

const strokeWidths = {
  sm: 24,
  md: 20
}

const MonoVolume = ({ size }) => (
  <svg width={`${sizes[size]}px`} height={`${sizes[size]}px`} viewBox="0 0 1147.53 450.07" style={{fill:'none', strokeWidth: strokeWidths[size]}}>
    <path d="M2947.94,940.31q-2.56,10.67-5.11,21.35c-4,16.85-12.55,30.86-30.17,34.62-28.24,6-56.89,10.09-86.74,15.21,6.34-33.22,1.44-62.4-19.23-87.72-21-25.72-48.44-37.86-81.42-36.25-28.2,1.38-51.86,13.16-69.84,35.14-23.78,29.08-28.3,61.93-16.45,99L2089,1006.83c10.27-69.38-37.55-115.38-88.62-119.22-26.93-2-50.92,5.61-71.53,23.21-26.6,22.73-36.15,52.5-34.52,87.75-14.64-2.89-28.3-5.35-41.84-8.32-25.26-5.55-36.84-20.19-36.09-46.33,1.06-37.18,2.83-74.34,3.84-111.52a123.16,123.16,0,0,1,15.78-58q29.75-52.91,59.88-105.61c10.48-18.31,25.82-30.11,47.33-32.09,13.54-1.25,27.17-1.59,40.77-2.19,133.59-5.88,267.16-9.87,400.83-1.55,49.75,3.1,98.06,10.93,141.85,36.36,56.93,33.06,113.57,66.62,170.43,99.79,5.3,3.09,11.09,6.1,17,7.21a619.91,619.91,0,0,1,125.42,37.8c49.68,20.74,85.75,55.29,108.35,104ZM2309.44,788V656H2131.86l-12.45,124.27Zm147.33,6.13c21,.89,41.93,1.85,62.91,2.23,3.16.06,8.16-2.08,9.27-4.56,7.79-17.41,21.43-24.44,41-24,0-12,.14-23.66-.15-35.34a7.37,7.37,0,0,0-3.21-5.09c-41.72-25.62-83.48-51.67-132-62.31-22.49-4.93-45.93-5.51-70-8.18V790.1C2395.81,791.48,2426.29,792.88,2456.77,794.18ZM2078.13,657.45c-15.14.69-28.64,1.39-42.14,1.91-25.73,1-51.47,2-77.21,2.72-16.25.42-27.91,7.11-35,22.11-6,12.6-13.2,24.61-19.38,37.13-12.79,25.89,1.42,50,30.14,51.24,43.33,1.9,86.67,3.65,131.62,5.53Zm531.47,134c6.25,5.34,23.63,8.93,30.21,4.11s6.29-12.84-.55-18.81a74.15,74.15,0,0,0-6.26-4.5l-32.42-22.79C2600.74,765.68,2596.27,780.09,2609.6,791.46Z" transform="translate(-1808.41 -620.26)"/><path d="M2654.18,986.75c.1-43.07,33.75-77.22,76.3-77.44,42-.22,76.7,34.62,76.77,77.15.07,41.48-35.08,76.12-77,75.87S2654.09,1027.92,2654.18,986.75Zm-737.85,0c.1-43.07,33.75-77.22,76.3-77.44,42-.22,76.7,34.62,76.77,77.15.07,41.48-35.08,76.12-77,75.87S1916.24,1027.92,1916.34,986.75Z" transform="translate(-1808.41 -620.26)"/>
  </svg>
)

export default MonoVolume
